// ** Theme Config Imports
// import themeConfig from 'src/configs/themeConfig';

const Radio = () => {
  return {
    MuiRadio: {
      styleOverrides: {
        // root: ({ ownerState, theme }) => ({

        // }),
        checked: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
  };
};

export default Radio;

import { CircularProgress, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect } from 'react';
import { getWordEvent } from '../../utils/common';
import { STANDARD_DATE_FORMAT } from '../../utils/constant';
import classes from './project-bar.module.sass';

export const ProjectBar = ({ loading, allProjects = [], planSince, onChangeFilter = () => { } }) => {
  const handleChangePlanSince = (planSince) => {
    const startDate = moment.unix(planSince).startOf('month').unix() + 36000;
    const endDate = moment.unix(planSince).endOf('month').unix() + 36000;
    onChangeFilter({ plan_since: startDate, plan_till: endDate, isArchived: false });
  };

  const items = allProjects.reduce((p, project) => {
    const month = moment.unix(project.dateStartPlan).startOf('month').format(STANDARD_DATE_FORMAT);
    const newA = [...(p[month] || []), project];
    return { ...p, [month]: newA };
  }, {});

  useEffect(() => {
    if (Object.values(items)?.[0]?.[0]?.dateStartPlan) handleChangePlanSince(Object.values(items)[0][0].dateStartPlan);
  }, [allProjects]);

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        {loading ? (
          <CircularProgress style={{ margin: '2rem auto', color: '#737791' }} />
        ) : (
          <>
            {Object.keys(items)
              .sort((a, b) => moment(a, STANDARD_DATE_FORMAT).diff(moment(b, STANDARD_DATE_FORMAT), 'day'))
              .map((key, i) => {
                const isSelected = moment.unix(planSince).isSame(moment(key, STANDARD_DATE_FORMAT), 'month');
                return (
                  <div
                    className={classNames(classes.item, planSince && isSelected && classes.selected)}
                    key={i}
                    onClick={() => handleChangePlanSince(items[key][0].dateStartPlan)}
                  >
                    <div className={classes.top}>{moment(key, STANDARD_DATE_FORMAT).format('MMMM YYYY')}</div>
                    <Typography
                      variant="subtitle1"
                      className={classes.bottom}
                      color={(theme) => (isSelected ? theme.palette.common.white : theme.palette.text.disabled)}
                    >
                      ({items[key].length})
                    </Typography>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};

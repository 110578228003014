import { Box, FormControl, InputLabel, Select as MuiSelect } from '@mui/material';
import { uniqueId } from 'lodash';

export function SelectHookForm({
  required,
  shrink = true,
  variant = 'standard',
  multiple,
  label,
  error,
  disabled,
  selectProps = {},
  errorComponent = <></>,
  children,
  sx = {},
}) {
  const labelId = uniqueId('hook-form-select-label__');
  return (
    <FormControl fullWidth error={error} disabled={disabled} margin="normal" variant={variant} sx={sx}>
      <InputLabel required={required} id={labelId} shrink={shrink}>
        {label}
      </InputLabel>
      <MuiSelect
        {...selectProps}
        value={selectProps.value || []}
        multiple={multiple}
        renderValue={multiple ? renderMultiSelect : undefined}
        label={label}
        labelId={labelId}
        // notched={false}
      >
        {children}
      </MuiSelect>
      {errorComponent}
    </FormControl>
  );
}

const renderMultiSelect = (selected) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, flexDirection: 'column' }}>
    {selected.map((value) => (
      <div
        key={value}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          overflowWrap: 'anywhere',
          width: '100%',
        }}
      >
        {value}
      </div>
    ))}
  </Box>
);

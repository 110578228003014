import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Project } from '../project';
import classes from './project-list.module.sass';
import { useBreakpoint } from '../../hooks';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import MuiTable from '../projects-table';
import { uniqueId } from 'lodash';
import Loader from '../loader';

export const ProjectList = ({ projects = [] }) => {
  const isLoadingProjects = useSelector((state) => state.app.isLoadingProjects);
  const isLoadingUserProjects = useSelector((state) => state.auth.isLoadingProjects);
  const [showMore, setShowMore] = useState(false);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const {
    windowSize: { width },
  } = useBreakpoint();
  const isLargeDesktop = width > 1024;

  return (
    <>
      {isLoadingProjects || isLoadingUserProjects ? (
        <Loader sx={{ margin: '2rem auto', paddingBottom: '2rem' }} />
      ) : projects.length === 0 ? (
        <div
          style={{
            textAlign: 'center',
            display: 'block',
            fontSize: 18,
            margin: '4rem auto',
            color: 'rgba(60, 60, 67, 0.6)',
          }}
        >
          {TEXT[lang].NO_PROJECTS}
        </div>
      ) : isLargeDesktop ? (
        <Box paddingBottom="3rem">
          <MuiTable headCells={[{ id: 1, label: 'name' }]} rows={projects} total={projects.length} />
        </Box>
      ) : (
        <>
          <div className={classes.root}>
            {projects.map(
              (project, i) =>
                (isLargeDesktop || showMore || i < 3) && <Project key={uniqueId('project_') + i} project={project} />
            )}
          </div>
          {projects.length > 3 && (
            <div className={classes.showMore} onClick={() => setShowMore(!showMore)}>
              <IconButton size="large">{showMore ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </div>
          )}
        </>
      )}
    </>
  );
};

export function ChatAltIcon({ color = '#FFA412' }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 12.1108C20 8.3396 20 6.45399 18.8284 5.28241C17.6569 4.11084 15.7712 4.11084 12 4.11084V4.11084C8.22876 4.11084 6.34315 4.11084 5.17157 5.28241C4 6.45399 4 8.3396 4 12.1108V18.1108C4 19.0536 4 19.5251 4.29289 19.8179C4.58579 20.1108 5.05719 20.1108 6 20.1108H12C15.7712 20.1108 17.6569 20.1108 18.8284 18.9393C20 17.7677 20 15.8821 20 12.1108V12.1108Z"
        stroke={color}
      />
      <path d="M9 10.1108L15 10.1108" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 14.1108H12" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

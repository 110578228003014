import { MenuOutlined } from '@mui/icons-material';
import { IconButton, Popover, Stack } from '@mui/material';
import React, { useState } from 'react';

function MenuPopover({ MenuElements, anchorOrigin, transformOrigin }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  return (
    <>
      <IconButton
        variant="outlined"
        color="secondary"
        onClick={(e) => (anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget))}
        sx={{ borderRadius: '4px', border: '1px solid' }}
      >
        <MenuOutlined />
      </IconButton>
      <Popover
        open={openMenu}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'left',
          }
        }
      >
        <Stack gap="8px" paddingX="18px" paddingY="12px">
          {MenuElements}
        </Stack>
      </Popover>
    </>
  );
}

export default MenuPopover;

import { KeyboardArrowDown } from '@mui/icons-material';
import { Avatar, Skeleton, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getFullName } from 'src/utils/common';

function ProfileSummary({ onClick }) {
  const user = useSelector((state) => state.auth.user);
  const isLoadingAvatar = useSelector((state) => state.auth.isLoadingAvatar);

  if (!user) return null;
  return (
    <Stack direction="row" gap="8px" alignItems="center" sx={{ cursor: 'pointer' }} onClick={onClick}>
      {isLoadingAvatar ? (
        <Skeleton variant="circular" sx={{ height: '48px', width: '48px', borderRadius: 0 }} />
      ) : (
        <Avatar
          alt="avatar"
          component="div"
          sizes="large"
          src={user?.avatar}
          sx={{ height: '48px', width: '48px', borderRadius: 0 }}
        >
          {(user.lastName?.[0] || '') + (user.firstName?.[0] || '')}
        </Avatar>
      )}
      <Stack>
        <Typography
          sx={{
            fontWeight: 600,
            overflowWrap: 'anywhere',
            textTransform: 'capitalize',
          }}
        >
          {getFullName(user)}
        </Typography>
        <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
          {user?.email}
        </Typography>
      </Stack>
      {onClick && <KeyboardArrowDown />}
    </Stack>
  );
}

export default ProfileSummary;

import { KeyboardArrowDown, Security } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from 'src/hooks';
import { AccountBalanceWalletIcon, BadgeIcon, BusinessCenterIcon, LogoutIcon } from 'src/icons';
import { logout } from 'src/store/actions/auth';
import { getFullName } from 'src/utils/common';
import { routes } from 'src/utils/routes';

function ProfileMenu() {
  const navigate = useNavigate();
  const { localizeText } = useLanguage();

  const user = useSelector((state) => state.auth.user);
  const isLoadingAvatar = useSelector((state) => state.auth.isLoadingAvatar);

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

  const handleDropdownClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleDropdownClose();
  };

  const handleNavigate = (path) => () => {
    navigate(path);
    handleDropdownClose();
  };

  if (!user) return null;
  return (
    <Box>
      <Stack
        direction="row"
        gap="8px"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={(e) => setProfileMenuAnchorEl(e.currentTarget)}
      >
        {isLoadingAvatar ? (
          <Skeleton variant="circular" sx={{ height: '48px', width: '48px', borderRadius: 0 }} />
        ) : (
          <Avatar
            alt="avatar"
            component="div"
            sizes="large"
            src={user?.avatar}
            sx={{ height: '48px', width: '48px', borderRadius: 0 }}
          >
            {(user.lastName?.[0] || '') + (user.firstName?.[0] || '')}
          </Avatar>
        )}
        <Stack>
          <Typography
            sx={{
              //   fontSize: inLeftMenu ? '1.2rem' : '1rem',
              fontWeight: 600,
              overflowWrap: 'anywhere',
              //   color: inLeftMenu ? 'white' : 'text.primary',
              textTransform: 'capitalize',
            }}
          >
            {getFullName(user)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              //   fontSize: inLeftMenu ? '1rem' : '0.8rem',
              //   color: inLeftMenu ? 'white' : 'text.disabled',
              overflowWrap: 'anywhere',
            }}
          >
            {user?.email}
          </Typography>
        </Stack>
        <KeyboardArrowDown />
      </Stack>

      <Menu
        anchorEl={profileMenuAnchorEl}
        open={!!profileMenuAnchorEl}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          minWidth: '200px',
        }}
        PaperProps={{
          sx: {
            borderRadius: '0 !important',
            boxShadow: '0px 6px 16px -4px #3A354133 !important',
            minWidth: '260px',
          },
        }}
      >
        <MenuItem onClick={handleNavigate(routes.profile.path)}>
          <ListItemIcon>
            <BadgeIcon />
          </ListItemIcon>
          <ListItemText>Персональные данные</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleNavigate(routes.faqs.path)}>
          <ListItemIcon>
            <Security sx={{ height: 12, width: 12 }} />
          </ListItemIcon>
          <ListItemText>{localizeText.SUPPORT_BUTTON}</ListItemText>
        </MenuItem>
        {/* <MenuItem>
          <ListItemIcon>
            <BusinessCenterIcon />
          </ListItemIcon>
          <ListItemText>Компании</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText>Счета</ListItemText>
        </MenuItem> */}
        <Divider sx={{ margin: '8px 16px', borderColor: '#D9D9D9' }} />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Выйти</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default ProfileMenu;

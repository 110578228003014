import {
  Avatar,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from 'src/hooks';
import { getLocalizationValue } from 'src/utils/common';
import { LOCALIZATION, MESSAGES, TEXT } from 'src/utils/constant';
import { routes } from 'src/utils/routes';
import JoinProjectButton from '../join-project-button';
import classes from './projects-table.module.sass';

function MuiTable({ rootSx, tableSx, rows, total, hasDividerHeader }) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang);
  const user = useSelector((state) => state.auth.user);
  const isLargeDesktop = useResponsive();

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: TEXT[lang].PROJECT_NAME,
      width: '45%',
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: TEXT[lang].DATE,
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: TEXT[lang].DESCRIPTION,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: TEXT[lang].STATUS,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer id={uniqueId('table__')} component={Paper} sx={rootSx} className={classes.root}>
      <Table size="medium" sx={tableSx}>
        <TableHead>
          <TableRow sx={{ background: '#F9FAFC' }}>
            {headCells.map((headCell, indexHead) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                width={headCell.width}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h3" sx={{ fontSize: '12px', fontWeight: 600, lineHeight: '20px' }}>
                    {headCell.label}
                  </Typography>
                  {hasDividerHeader && indexHead < headCells.length - 1 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ width: '2px', height: '14px !important', margin: 'auto 0' }}
                    />
                  )}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {total === 0 ? (
            <TableRow>
              <TableCell colSpan={headCells.length}>
                <Typography variant="h6" color="text.secondary" sx={{ margin: '0 24px', textAlign: 'center' }}>
                  {TEXT[lang].NO_DATA}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, index) => {
                // const webBigImage = row.photos?.web_big?.[0];
                // const mobileBigImage = row.photos?.small_mobile?.[0] || row.photos?.mobile_big?.[0];
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.name}
                    role="checkbox"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(routes.projectDetail.path.replace(':projectUuid', row.uuid))}
                  >
                    <TableCell component="th" scope="row" padding="none" sx={{ minWidth: '60%' }}>
                      <Stack direction="row" alignItems="center" flexWrap="nowrap" gap="8px" paddingY="10px">
                        <LazyLoad>
                          <Avatar alt="" src={row.photos?.logo?.[0]} />
                        </LazyLoad>
                        <Typography variant="body1" sx={{ fontSize: '1.15rem', fontWeight: 500 }}>
                          {getLocalizationValue(row, lang, 'fullName') || row.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.dateStartPlan && (
                        <>
                          <Typography component="span" variant="body1" sx={{ fontSize: '1rem', fontWeight: 500 }}>
                            {moment
                              .unix(row.dateStartPlan)
                              .format(
                                moment.unix(row.dateStartPlan).isSame(moment.unix(row.dateFinishPlan), 'month')
                                  ? 'DD'
                                  : 'DD MMMM'
                              )}
                          </Typography>
                          {' - '}
                          <Typography
                            component="span"
                            variant="body1"
                            sx={{ fontSize: '1rem', fontWeight: 500 }}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {moment.unix(row.dateFinishPlan).format('DD MMMM')}
                          </Typography>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                        }}
                        variant="body1"
                        color="GrayText"
                      >
                        {row.localization?.[LOCALIZATION.ru_RU]?.comment}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <JoinProjectButton project={row} hideJoinedText={true} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
      {total > 10 && (
        <TablePagination
          labelRowsPerPage={`${MESSAGES[lang].ROWS_PER_PAGE}:`}
          labelDisplayedRows={({ count, from, to }) => {
            return `${from}-${to} ${TEXT[lang].OF} ${count}`.toLowerCase();
          }}
          page={page - 1}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 50]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
}

export default MuiTable;

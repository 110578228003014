import { cloneDeep, isArray } from 'lodash';
import queryString from 'query-string';
import { generalAPIs, projectsAPIs, userAPIs } from '../../services';
import { getTransMessages, getTransText, uniqueArray } from '../../utils/common';
import { DOCUMENT_TYPES } from '../../utils/constant';
import { createCustomNotification, createErrorNotification, createNotification } from '../../utils/notifications';
import * as constants from '../constants/app';
import * as authConstants from '../constants/auth';
import { dispatch, getCurrentState } from '../index';
import { getUserProjects } from './auth';

export const getCountriesList = async () => {
  try {
    const response = await generalAPIs.getCountries();
    dispatch({ type: constants.SET_COUNTRIES, countries: response.message });
    return response.message;
  } catch (error) {
    return [];
  }
};

export const setCountriesList = (countries) => {
  dispatch({ type: constants.SET_COUNTRIES, countries: countries });
};

export const getRolesList = async (query) => {
  try {
    const response = await generalAPIs.getRoles(query);
    const roles = response.message || [];
    dispatch({ type: constants.SET_ROLES, roles });
    return roles;
  } catch (error) {
    return [];
  }
};

export const getProjectsList = async (query, callAction = true) => {
  try {
    dispatch({ type: constants.SET_LOADING, dataType: 'isLoadingProjects', loading: true });
    const response = await projectsAPIs.getProjects(query);
    if (callAction) {
      dispatch({ type: constants.SET_PROJECTS, projects: response.message });
    }
    return response.message;
  } catch (error) {
    return [];
  } finally {
    dispatch({ type: constants.SET_LOADING, dataType: 'isLoadingProjects', loading: false });
  }
};

export const joinProject = async (id, roleUuid) => {
  try {
    const transMessages = getTransMessages();
    const response = await projectsAPIs.joinProject(id, roleUuid);
    const user = response.message || {};
    dispatch({ type: authConstants.AUTH__UPDATE_USER, user });
    createNotification(transMessages.SUCCESSFUL_PARTICIPATION);
    await getUserProjects();
  } catch (error) {
    console.log(error);
  }
};

export const leaveProject = async (id) => {
  const transMessage = getTransMessages();
  try {
    const response = await projectsAPIs.leaveProject(id);
    const user = response.message || {};
    dispatch({ type: authConstants.AUTH__UPDATE_USER, user });
    createNotification(transMessage.LEAVE_SUCCESS);
    await getUserProjects();
  } catch (error) {
    console.log(error);
  }
};

export const getProjectPackages = async (id, query) => {
  try {
    const response = await projectsAPIs.getProjectPackages(id, query);
    const packages = response.message || [];
    return packages;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getPhotosOfProject = async (id) => {
  try {
    const response = await projectsAPIs.getPhotosOfProject(id);
    const photos = response.message || [];
    return photos;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const removeFromOrder = (projectUuid, _package) => {
  let newOrder = [...(getCurrentState().app.order || [])];
  const indexProject = newOrder.findIndex((item) => item.projectUuid === projectUuid);
  if (indexProject !== -1) {
    newOrder = newOrder.map((item, i) => {
      return i === indexProject
        ? {
            ...item,
            packages: item.packages.map((p) =>
              p.package.id === _package.id ? { ...p, count: Math.max(p.count - 1, 0) } : p
            ),
          }
        : item;
    });

    newOrder[indexProject].packages = uniqueArray(newOrder[indexProject].packages, (value, index, self) => {
      return self.findIndex((item) => item.package.id === value.package.id) === index && value.count !== 0;
    });
  }

  dispatch({ type: constants.SET_ORDER, order: newOrder.filter((item) => item.packages.length > 0) });
};

export const countOrder = (projectUuid, _package, count) => {
  let newOrder = [...(getCurrentState().app.order || [])];
  const indexProject = newOrder.findIndex((item) => item.projectUuid === projectUuid);
  if (indexProject !== -1) {
    newOrder = newOrder.map((item, i) => {
      return i === indexProject
        ? {
            ...item,
            packages: item.packages.map((p) =>
              p.package.id === _package.id ? { ...p, count: Math.max(count, 0) } : p
            ),
          }
        : item;
    });

    newOrder[indexProject].packages = uniqueArray(newOrder[indexProject].packages, (value, index, self) => {
      return self.findIndex((item) => item.package.id === value.package.id) === index && value.count !== 0;
    });
  }

  dispatch({ type: constants.SET_ORDER, order: newOrder.filter((item) => item.packages.length > 0) });
};

export const addToOrder = (projectUuid, _package, onGotoBasket) => {
  const transMessages = getTransMessages();
  const transText = getTransText();
  let newOrder = [...(getCurrentState().app.order || [])];
  const indexProject = newOrder.findIndex((item) => item.projectUuid === projectUuid);
  if (indexProject === -1) {
    newOrder = [...newOrder, { projectUuid, packages: [{ package: _package, count: 1 }] }];
  } else {
    const indexPackage = newOrder[indexProject].packages.findIndex((p) => p.package.id === _package.id);
    if (indexPackage === -1) {
      newOrder[indexProject].packages = [...newOrder[indexProject].packages, { package: _package, count: 1 }];
    } else {
      newOrder = newOrder.map((item, i) => {
        return i === indexProject
          ? {
              ...item,
              packages: item.packages.map((p) => (p.package.id === _package.id ? { ...p, count: p.count + 1 } : p)),
            }
          : item;
      });
    }

    newOrder[indexProject].packages = uniqueArray(newOrder[indexProject].packages, (value, index, self) => {
      return self.findIndex((item) => item.package.id === value.package.id) === index;
    });
  }

  dispatch({ type: constants.SET_ORDER, order: newOrder });
  createCustomNotification(transMessages.ADDED_TO_CART, {
    message: transText.BASKET,
    onClick: onGotoBasket,
  });
};

export const removePackagesInOrder = async (projectUuid, packages) => {
  let newOrder = [...(getCurrentState().app.order || [])];
  const indexProject = newOrder.findIndex((item) => item.projectUuid === projectUuid);
  newOrder[indexProject].packages = newOrder[indexProject].packages.filter(
    (p) => !packages.find((_p) => _p.package.id === p.package.id)
  );
  if (newOrder[indexProject].packages?.length === 0) {
    newOrder.splice(indexProject, 1);
  }
  dispatch({ type: constants.SET_ORDER, order: newOrder });
};

export const getDocsOfMembers = async (projectUuid, members) => {
  try {
    const avatars = await Promise.all(
      members.map((m) => getDocumentOfMember(m.uuid, DOCUMENT_TYPES.AVATAR, projectUuid))
    );
    dispatch({
      type: constants.SET_MEMBERS,
      members: members.map((m, i) => ({
        ...m,
        // passportFile: passports[i],
        avatarFile: avatars[i],
      })),
    });
  } catch (error) {
    console.log(error);
  }
};

export const getMembersByProject = async ({ projectUuid, memberUuid, limit, offset, ...restArgs }) => {
  try {
    const response = await userAPIs.getMembersOfProject(
      queryString.stringify({
        projectUuid,
        uuid: memberUuid,
        limit: limit ?? 20,
        offset: offset ?? 0,
        ...restArgs,
      })
    );
    const mainUser = response.message.mainUser;
    const members = response.message.members;
    const allMembers = [...(mainUser ? [mainUser] : []), ...(isArray(members) ? members : response.message)];
    // const passports = await Promise.all(members.map((m) => getDocumentOfMember(m.uuid, DOCUMENT_TYPES.PASSPORT)));
    dispatch({
      type: constants.SET_MEMBERS,
      members: allMembers,
    });
    getDocsOfMembers(projectUuid, allMembers);
    return { members: allMembers, total: response.message?.totalCount || 0 };
  } catch (error) {
    console.log(error);
  }
};

export const getMemberByUuid = async (projectUuid, memberUuid) => {
  try {
    const members = getCurrentState().app.members || [];
    const response = await userAPIs.getMembersOfProject(projectUuid, memberUuid);
    const newMembers = response.message || [];
    const selectedMember = newMembers.find((m) => m.uuid === memberUuid);
    if (!selectedMember) return;

    dispatch({
      type: constants.SET_MEMBERS,
      members: members.map((m) => (m.uuid === memberUuid ? { ...m, ...selectedMember } : m)),
    });
  } catch (error) {
    console.log(error);
  }
};

export const deletePassportFileOfMember = async (memberUuid) => {
  try {
    await userAPIs.deletePassportOfMember(memberUuid);
  } catch (error) {
    console.log(error);
  }
};

export const getDocumentOfMember = async (memberUuid, type, projectUuid) => {
  try {
    const document = await userAPIs.getDocumentMember(memberUuid, type, projectUuid);
    return document;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getMyTicket = async (memberUuid, projectUuid, returnBlob) => {
  const transMessages = getTransMessages();
  const confirmationLetter = await getDocumentOfMember(memberUuid, DOCUMENT_TYPES.CONFIRMATION_LETTER, projectUuid);
  if (returnBlob) {
    return confirmationLetter;
  }
  if (confirmationLetter) {
    const url = window.URL.createObjectURL(confirmationLetter);
    // const a = document.createElement('a');
    // a.style.display = 'none';
    // a.href = url;
    // a.target = '_blank';
    // a.download = 'confirmation_letter.jpeg';
    // document.body.appendChild(a);
    // a.click();
    return url;
    // window.URL.revokeObjectURL(url);
  } else {
    createErrorNotification(transMessages.DO_NOT_HAVE_TICKET);
  }
};

export const uploadDocumentOfMember = async (memberUuid, file) => {
  try {
    const formData = new FormData();
    formData.append('fileDocument', file, file.name);
    formData.append('type', DOCUMENT_TYPES.PASSPORT);
    const response = await userAPIs.uploadDocumentOfMember(memberUuid, formData);
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const checkAndCreateMember = async (
  { projectUuid, email, invitationUuid, roleUuid, lastName, firstName, middleName },
  onErrorCallback = () => {}
) => {
  const transMessages = getTransMessages();
  try {
    const response = await userAPIs.checkAndCreateNewUser({
      projectUuid,
      email,
      invitationUuid,
      roleUuid,
      lastName,
      firstName,
      middleName,
    });
    const member = response.message || [];
    return member;
  } catch (error) {
    console.log(error);
    switch (error.code) {
      case 409:
        createErrorNotification(transMessages.ALREADY_REGISTERED);
        break;
      case 400:
        createErrorNotification(transMessages.ALREADY_JOINED_PROJECT);
        break;
      default:
        break;
    }
    onErrorCallback(error.code);
  }
};

export const updateMember = async (memberUuid, body) => {
  try {
    const members = getCurrentState().app.members || [];
    const response = await userAPIs.updateMember(memberUuid, body);
    const newData = response.message || {};
    dispatch({
      type: constants.SET_MEMBERS,
      members: members.map((m, i) => (m.uuid === memberUuid ? { ...m, ...newData, ...body.updatingUserFields } : m)),
    });
  } catch (error) {
    console.log(error, error.code);
    createErrorNotification(error.message.error);
  }
};

export const uploadAvatarUser = async (memberUuid, avatar) => {
  try {
    const members = getCurrentState().app.members || [];
    const formData = new FormData();
    formData.append('fileDocument', avatar, `${memberUuid}-avatar.png`);
    formData.append('type', DOCUMENT_TYPES.AVATAR);
    await userAPIs.uploadDocumentOfMember(memberUuid, formData);
    dispatch({
      type: constants.SET_MEMBERS,
      members: members.map((m, i) => (m.uuid === memberUuid ? { ...m, avatarFile: avatar } : m)),
    });
    return true;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getServicesOfPackages = async (projectUuid, packageUuids) => {
  try {
    const response = await projectsAPIs.getServicesByPackage(projectUuid, queryString.stringify({ packageUuids }));
    return response.message || {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const setPayingOrders = (payingOrders) => {
  dispatch({ type: constants.SET_PAYING_ORDERS, payingOrders });
};

export const setLang = (lang) => {
  dispatch({ type: constants.SET_LANG, lang });
};

export const setProjectSettings = (settings) => {
  dispatch({ type: constants.SET_PROJECT_SETTINGS, payload: settings });
};

export const setNotifications = (notifications) => {
  dispatch({ type: constants.SET_NOTIFICATIONS, payload: notifications });
};

export const setPageState = (pageState) => {
  dispatch({ type: constants.SET_PAGE_STATE, payload: pageState });
};

export const setCurrentProject = (project) => {
  dispatch({ type: constants.SET_CURRENT_PROJECT, payload: project });
};

import { Add, ChevronLeft, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CardContent,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { keyBy, uniqueId } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
import MemberProfile from 'src/components/members/MemberProfile';
import OrderItem from 'src/components/order/OrderItem';
import { useLanguage } from 'src/hooks';
import { userAPIs } from 'src/services';
import { getMembersByProject } from 'src/store/actions/app';
import { getMyOrder } from 'src/store/actions/auth';
import { getFullName } from 'src/utils/common';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { routes } from 'src/utils/routes';

function DistributeOrders({ project, projectUuid }) {
  const user = useSelector((state) => state.auth.user);
  const myOrders = useSelector((state) => state.auth.myOrders);
  const allMembers = useSelector((state) => state.app.members);

  const navigate = useNavigate();
  const location = useLocation();
  const { localizeText, localizeMessage } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [ordersByMember, setOrdersByMember] = useState({});
  const [isLoadingMember, setIsLoadingMember] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const members = useMemo(
    () => (allMembers ? allMembers.filter((m) => user.uuid === m.uuid || m.accreditedBy !== '') : []),
    [allMembers, user]
  );

  useEffect(() => {
    if (projectUuid) {
      Promise.all([getMembersByProject({ projectUuid }), getMyOrder(projectUuid)]).finally(() => setIsLoading(false));
    }
  }, [projectUuid]);

  useEffect(() => {
    if (isLoadingMember) {
      setTimeout(() => setIsLoadingMember(false), 200);
    }
  }, [isLoadingMember]);

  useEffect(() => {
    if (!isLoading && !members?.length) {
      navigate(routes.myOrder.path.replace(':projectUuid', projectUuid));
    }
  }, [members, isLoading]);

  const selectedOrderIds = useMemo(() => location.state?.selectedOrders || [], [location]);
  const selectedOrders = (myOrders?.filter((order) => selectedOrderIds.includes(order.id)) ?? []).map((order, i) => {
    const date = new Date(order.createdAt * 1000);
    const usedOrderArr = Object.values(ordersByMember)
      .flat()
      .filter((o) => o.orderId === order.id);
    const newProducts = order.products.map((prod) => {
      const usedAmount = usedOrderArr.reduce((p, c) => p + (c.productsById?.[prod.productId] ?? 0), 0) || 0;
      return {
        ...prod,
        amount: prod.amount - usedAmount,
        baseAmount: prod.amount,
      };
    });
    return {
      ...order,
      title: `${i + 1}. ${localizeText.ORDER} ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
      products: newProducts,
      productsById: keyBy(newProducts, 'productId'),
    };
  });
  const ordersById = keyBy(selectedOrders, 'id');

  const canSave = selectedOrders.every((order) => order.products.every((product) => product.amount === 0));

  const handleDistribute = async () => {
    let body = {};
    const ordersMap = Object.entries(ordersByMember);
    ordersMap.forEach(([key, value]) => {
      value.forEach((order) => {
        const orderId = order.orderId;
        if (orderId) {
          body = {
            ...body,
            [orderId]: (body[orderId] || []).concat(
              Object.keys(order.productsById).reduce((p, c) => {
                return [...p, { userUuid: key, packageUuid: c, count: order.productsById[c] }];
              }, [])
            ),
          };
        }
      });
    });

    try {
      setIsSubmitting(true);
      await userAPIs.distributeOrders({ projectUuid, data: body });
      createNotification(localizeMessage.SAVE_SUCCESSFULLY, { type: 'success' });
      navigate(routes.myOrder.path.replace(':projectUuid', projectUuid));
    } catch (error) {
      console.error(error);
      createErrorNotification(localizeMessage.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
        <Box
          container
          sx={{
            display: 'flex',
            marginTop: '-2rem',
            marginBottom: '2rem',
            boxShadow: '0px 6px 16px -4px #3A35411A, 0px 2px 12px -4px #3A354114, 0px 2px 12px -4px #3A35411A',
            backgroundColor: 'white',
            width: '100%',
            padding: '20px',
          }}
        >
          <IconButton onClick={() => navigate(routes.myOrder.path.replace(':projectUuid', projectUuid))}>
            <ChevronLeft />
          </IconButton>
          <h2 style={{ margin: '0 0 0 20px', fontWeight: 'bold' }}>{localizeText.DISTRIBUTE_ORDERS}</h2>
        </Box>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Stack paddingY="24px" gap="24px">
          {selectedOrders.map((order, i) => (
            <Stack
              key={i}
              direction={{ xs: 'column', md: 'row' }}
              gap="18px"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h6">{order.title}:</Typography>
              <Stack gap="4px">
                {order.products?.map((product, indexP) => (
                  <Typography key={indexP} variant="h6" sx={{ color: (theme) => theme.palette.text.secondary }}>
                    - {product.comment} x {product.amount}
                  </Typography>
                ))}
              </Stack>
            </Stack>
            // <OrderItem
            //   key={i}
            //   order={order}
            //   project={project}
            //   prefixOrderId={`${i + 1}.`}
            //   showPayment={false}
            //   showDelete={false}
            // />
          ))}

          {canSave && (
            <Alert severity="warning" sx={{ fontSize: '1rem' }}>
              {localizeMessage.ALL_PACKAGES_WERE_DISTRIBUTED}
            </Alert>
          )}
          <Divider sx={{ width: '100%', marginY: '12px' }} />

          {members.length > 0 && (
            <Grid container spacing={8}>
              {members.map((member, i) => {
                const selectedMemberUuid = member.uuid;
                if ((ordersByMember[selectedMemberUuid]?.length ?? 0) === 0) {
                  setOrdersByMember({
                    ...ordersByMember,
                    [selectedMemberUuid]: [
                      {
                        orderId: selectedOrders.find((order) => order.products.some((prod) => prod.amount > 0))?.id,
                        productsById: {},
                      },
                    ],
                  });
                }

                return (
                  <>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          padding: '8px 16px',
                          borderRadius: '30px',
                          width: '100%',
                          textAlign: 'center',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          color: (theme) => theme.palette.text.primary,
                          background: (theme) => theme.palette.grey[100],
                        }}
                      >
                        <MemberProfile member={member} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Stack gap="8px">
                        {ordersByMember[selectedMemberUuid]?.map((orderData, orderDataIndex) => (
                          <React.Fragment>
                            <FormControl margin="normal">
                              <InputLabel shrink={true} required variant="standard">
                                {localizeText.ORDER}
                              </InputLabel>
                              <Select
                                variant="standard"
                                margin="dense"
                                label={localizeText.ORDER}
                                value={orderData.orderId}
                                onChange={(e) => {
                                  let newOrders = ordersByMember[selectedMemberUuid];
                                  newOrders[orderDataIndex].orderId = e.target.value;
                                  newOrders[orderDataIndex].productsById = {};
                                  setOrdersByMember({ ...ordersByMember, [selectedMemberUuid]: newOrders });
                                }}
                              >
                                {selectedOrders
                                  .filter(
                                    (o) =>
                                      orderData.orderId === o.id ||
                                      (o.products.some((prod) => prod.amount > 0) &&
                                        !ordersByMember[selectedMemberUuid].map((oo) => oo.orderId).includes(o.id))
                                  )
                                  .map((order) => (
                                    <MenuItem key={order.id} value={order.id}>
                                      {order.title}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            {ordersById[orderData.orderId]?.products?.map((product) => (
                              <Stack direction="row" alignItems="center" gap="8px">
                                <Typography variant="body1">- {product.comment} X </Typography>
                                <TextField
                                  type="number"
                                  sx={{ width: '100px' }}
                                  margin="none"
                                  size="small"
                                  value={orderData.productsById?.[product.productId] || 0}
                                  onChange={(e) => {
                                    const value = Math.max(
                                      0,
                                      Math.min(
                                        e.target.value,
                                        (orderData.productsById?.[product.productId] || 0) +
                                        ordersById[orderData.orderId].productsById[product.productId]?.amount
                                      )
                                    );

                                    let newOrders = ordersByMember[selectedMemberUuid];
                                    newOrders = newOrders.map((o) => {
                                      return o.orderId === orderData.orderId
                                        ? {
                                          ...o,
                                          productsById: {
                                            ...o.productsById,
                                            [product.productId]: value,
                                          },
                                        }
                                        : o;
                                    });
                                    setOrdersByMember({ ...ordersByMember, [selectedMemberUuid]: newOrders });
                                  }}
                                />
                              </Stack>
                            ))}
                            <Divider sx={{ width: '100%', borderStyle: 'dashed', marginY: '16px' }} />
                          </React.Fragment>
                        ))}

                        {!canSave && ordersByMember[selectedMemberUuid]?.length < selectedOrderIds?.length && (
                          <Button
                            variant="outlined"
                            startIcon={<Add />}
                            sx={{ width: 'fit-content' }}
                            onClick={() => {
                              setOrdersByMember({
                                ...ordersByMember,
                                [selectedMemberUuid]: (ordersByMember[selectedMemberUuid] || []).concat([
                                  { orderId: '', packages: [{ packageId: '', count: 0 }] },
                                ]),
                              });
                            }}
                          >
                            {localizeText.ADD_ORDER}
                          </Button>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                );
              })}

              {/* <Grid item xs={12} md={4}>
                <Stack gap="24px">
                  {members.length > 1 && (
                    <>
                      <Divider />
                      <Stack>
                        <Button
                          variant="text"
                          color="secondary"
                          size="small"
                          sx={{ textTransform: 'uppercase' }}
                          onClick={() => setShowChangeMember(!showChangeMember)}
                          endIcon={showChangeMember ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        >
                          {showChangeMember ? localizeText.HIDE_ALL_MEMBERS : localizeText.SHOW_ALL_MEMBERS}
                        </Button>
                      </Stack>

                      <Collapse in={showChangeMember} sx={{ width: '100%' }} timeout="auto" unmountOnExit>
                        <Stack gap="16px">
                          {members.map((member, memberIndex) => (
                            <Button
                              key={uniqueId('member__')}
                              onClick={() => {
                                setIsLoadingMember(true);
                                setIndexSelectedMember(memberIndex);
                                setShowChangeMember(false);
                              }}
                              disabled={indexSelectedMember === memberIndex}
                              sx={{
                                padding: '8px 16px',
                                borderRadius: '30px',
                                width: '100%',
                                textAlign: 'center',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                color: (theme) => theme.palette.text.primary,
                                background: (theme) => theme.palette.grey[200],
                                '&:hover': {
                                  background: (theme) => theme.palette.primary.light,
                                  color: (theme) => theme.palette.primary.contrastText,
                                },
                              }}
                            >
                              <MemberProfile member={member} />
                            </Button>
                          ))}
                        </Stack>
                      </Collapse>
                    </>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={8}>
                {isLoadingMember ? (
                  <Loader />
                ) : (
                  <Stack gap="8px">
                    {ordersByMember[selectedMemberUuid]?.map((orderData, orderDataIndex) => (
                      <React.Fragment>
                        <FormControl margin="normal">
                          <InputLabel shrink={true} required variant="standard">
                            {localizeText.ORDER}
                          </InputLabel>
                          <Select
                            variant="standard"
                            margin="dense"
                            label={localizeText.ORDER}
                            value={orderData.orderId}
                            onChange={(e) => {
                              let newOrders = ordersByMember[selectedMember.uuid];
                              newOrders[orderDataIndex].orderId = e.target.value;
                              newOrders[orderDataIndex].productsById = {};
                              setOrdersByMember({ ...ordersByMember, [selectedMember.uuid]: newOrders });
                            }}
                          >
                            {selectedOrders
                              .filter(
                                (o) =>
                                  orderData.orderId === o.id ||
                                  (o.products.some((prod) => prod.amount > 0) &&
                                    !ordersByMember[selectedMember.uuid].map((oo) => oo.orderId).includes(o.id))
                              )
                              .map((order) => (
                                <MenuItem key={order.id} value={order.id}>
                                  {order.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {ordersById[orderData.orderId]?.products?.map((product) => (
                          <Stack direction="row" alignItems="center" gap="8px">
                            <Typography variant="body1">- {product.comment} X </Typography>
                            <TextField
                              type="number"
                              sx={{ width: '100px' }}
                              margin="none"
                              size="small"
                              value={orderData.productsById?.[product.productId] || 0}
                              onChange={(e) => {
                                const value = Math.max(
                                  0,
                                  Math.min(
                                    e.target.value,
                                    (orderData.productsById?.[product.productId] || 0) +
                                      ordersById[orderData.orderId].productsById[product.productId]?.amount
                                  )
                                );

                                let newOrders = ordersByMember[selectedMemberUuid];
                                newOrders = newOrders.map((o) => {
                                  return o.orderId === orderData.orderId
                                    ? {
                                        ...o,
                                        productsById: {
                                          ...o.productsById,
                                          [product.productId]: value,
                                        },
                                      }
                                    : o;
                                });
                                setOrdersByMember({ ...ordersByMember, [selectedMemberUuid]: newOrders });
                              }}
                            />
                          </Stack>
                        ))}
                        <Divider sx={{ width: '100%', borderStyle: 'dashed', marginY: '16px' }} />
                      </React.Fragment>
                    ))}

                    {!canSave && ordersByMember[selectedMemberUuid]?.length < selectedOrderIds?.length && (
                      <Button
                        variant="outlined"
                        startIcon={<Add />}
                        sx={{ width: 'fit-content' }}
                        onClick={() => {
                          setOrdersByMember({
                            ...ordersByMember,
                            [selectedMember.uuid]: (ordersByMember[selectedMember.uuid] || []).concat([
                              { orderId: '', packages: [{ packageId: '', count: 0 }] },
                            ]),
                          });
                        }}
                      >
                        {localizeText.ADD_ORDER}
                      </Button>
                    )}
                  </Stack>
                )}
              </Grid> */}
              <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ width: { xs: '100%', md: '50%' }, textTransform: 'capitalize' }}
                  disabled={!canSave || isSubmitting}
                  startIcon={isSubmitting ? <Loader size="1rem" color="grey" /> : <></>}
                  onClick={handleDistribute}
                >
                  {localizeText.SAVE_CHANGES}
                </Button>
              </Grid>
            </Grid>
          )}
        </Stack>
      )}
    </div>
  );
}

export default DistributeOrders;

export function LogoutIcon({ color = '#544F5A' }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 3.5L7.795 4.205L9.085 5.5H4V6.5H9.085L7.795 7.79L8.5 8.5L11 6L8.5 3.5ZM2 2.5H6V1.5H2C1.45 1.5 1 1.95 1 2.5V9.5C1 10.05 1.45 10.5 2 10.5H6V9.5H2V2.5Z"
        fill={color}
      />
    </svg>
  );
}

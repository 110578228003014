import { ChevronLeft } from '@mui/icons-material';
import { Box, CardContent, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { ChangePasswordForm, ConfirmModal, MainLayout, PackageDetailModal, ViewImageModal } from '../../components';
import DeleteIcon from '../../icons/delete';
import { deleteOrder, getArchivedOrder } from '../../store/actions/auth';
import { ENDPOINT, EVENTS, LOCALIZATION, TEXT, PAYMENT_METHODS, STATUS_PAYMENT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { viewFile } from '../../utils/request';
import classes from './archived-orders.module.sass';
import Loader from 'src/components/loader';

const ArchivedOrderPage = ({ }) => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const { projectUuid: id } = useParams();
  const archivedOrders = useSelector((state) => state.auth.archivedOrders);
  const projects = useSelector((state) => state.auth.projects);

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const breadcrumbs = [
    { path: '/', title: TEXT[lang].MAIN_PAGE },
    { path: '/projects/' + project?.uuid, title: project?.localization?.[lang].shortName },
    { path: null, title: TEXT[lang].ARCHIVED_ORDERS },
  ];

  useEffect(() => {
    const fetchData = async (id) => {
      await getArchivedOrder(id);
      const project = projects.find((project) => project.uuid === id);
      setProject(project);
      setIsLoading(false);
    };

    fetchData(id);
  }, [id]);

  const handleDownFile = async (docUuid) => {
    try {
      if (isDownloading) return;
      setIsDownloading(true);
      await viewFile(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.DOCUMENTS}/${docUuid}`);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDeleteOrder = (order) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteOrder(order.id);
      },
    });
  };

  const handleShowDetail = (projectUuid, products) => {
    eventBus.emit(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, {
      projectUuid,
      packages: products.map((p) => ({
        ...p,
        localization: {
          [lang]: {
            name: p.comment,
          },
        },
        id: p.productId,
      })),
    });
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <Box
          container
          sx={{
            display: 'flex',
            marginTop: '-2rem',
            marginBottom: '2rem',
            boxShadow: '0px 6px 16px -4px #3A35411A, 0px 2px 12px -4px #3A354114, 0px 2px 12px -4px #3A35411A',
            backgroundColor: 'white',
            width: '100%',
            padding: '20px',
          }}
        >
          <IconButton onClick={() => navigate(routes.myOrder.path.replace(':projectUuid', id))}>
            <ChevronLeft />
          </IconButton>
          <h2 className={classes.title}>{TEXT[lang].ARCHIVED_ORDERS}</h2>
        </Box>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ background: 'transparent', borderRadius: '30px', marginTop: '16px' }}>
          {archivedOrders.length ? (
            <Stack alignContent="flex-start">
              {archivedOrders.map((order, index) => {
                var date = new Date(order.createdAt * 1000);
                return (
                  project && (
                    <div className={classes.content} key={index}>
                      {order.status === 'undetermined' && (
                        <IconButton className={classes.deleteIcon} onClick={() => handleDeleteOrder(order)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                      <Stack direction="row" className={classes.project}>
                        <Stack>
                          <div className={classes.no}>
                            {TEXT[lang].ORDER} {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
                          </div>
                          <img className={classes.image} width="150" height="150" src={project.photos?.logo?.[0]} />
                        </Stack>

                        <Stack width="100%" justifyContent="space-evenly">
                          <Stack
                            className={classes.top}
                            direction="row"
                            justifyContent="space-between"
                            sx={{ gap: '18px' }}
                          >
                            <Stack>
                              <Stack direction="row" spacing={1}>
                                <div className={classes.invoice}>
                                  {order.properties?.invoiceDocId
                                    ? PAYMENT_METHODS.bankAccount.label[lang]
                                    : PAYMENT_METHODS.bankCard.label[lang]}
                                </div>
                                {order.thezisId && (
                                  <div
                                    className={classes.orderNumber}
                                  >{`${TEXT[lang].NUMBER_ORDER}: ${order.thezisId}`}</div>
                                )}
                              </Stack>
                              <div className={classes.name} style={{ marginBottom: 12 }}>
                                {project.localization?.[lang]?.name || project.name}
                              </div>
                              <Stack spacing={0.5}>
                                {order.products?.map((product, indexP) => (
                                  <div key={indexP} className={classes.product}>
                                    {product.comment} x {product.amount}
                                  </div>
                                ))}
                                <Typography
                                  textAlign="left"
                                  color="#737791"
                                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                  onClick={() => handleShowDetail(project.uuid, order.products)}
                                >
                                  {TEXT[lang].SHOW_PACKAGE_DETAILS}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack alignItems="center">
                              <div
                                className={classes.invoice}
                                style={{ background: STATUS_PAYMENT[order.status]?.color }}
                              >
                                {STATUS_PAYMENT[order.status].label[lang]}
                              </div>

                              <div className={classes.price} style={{ margin: '12px 0' }}>
                                <NumberFormat
                                  value={order.totalSum}
                                  suffix=" руб"
                                  decimalScale={2}
                                  displayType="text"
                                  thousandSeparator=" "
                                  decimalSeparator=","
                                />
                              </div>
                              {order.properties?.invoiceDocId && (
                                <div
                                  className={classes.viewPDF}
                                  style={{ color: isDownloading && 'grey', cursor: isDownloading && 'auto' }}
                                  onClick={() => handleDownFile(order.properties?.invoiceDocId)}
                                >
                                  «Скачать счет»
                                </div>
                              )}
                              <div className={classes.invoice} style={{ marginTop: '12px', background: 'grey' }}>
                                {TEXT[lang].ARCHIVED}
                              </div>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack className={classes.projectMobile}>
                        <Stack>
                          <div className={classes.no} style={{ textAlign: 'left' }}>
                            Заказ {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
                          </div>
                          <img className={classes.image} width="100%" height="220" src={project.photos?.logo?.[0]} />
                        </Stack>

                        <Stack width="100%" justifyContent="space-evenly">
                          <Stack
                            className={classes.top}
                            justifyContent="space-between"
                            sx={{ gap: '18px', marginTop: '12px' }}
                          >
                            <Stack>
                              <div className={classes.invoice}>
                                {order.properties?.invoiceDocId
                                  ? PAYMENT_METHODS.bankAccount.label[lang]
                                  : PAYMENT_METHODS.bankCard.label[lang]}
                              </div>
                              {order.thezisId && (
                                <div
                                  className={classes.orderNumber}
                                  style={{ marginTop: 12 }}
                                >{`${TEXT[lang].NUMBER_ORDER}: ${order.thezisId}`}</div>
                              )}
                              <div className={classes.name} style={{ marginBottom: 12 }}>
                                {project.localization?.[lang]?.name || project.name}
                              </div>
                              <Stack spacing={0.5}>
                                {order.products?.map((product, indexP) => (
                                  <div key={indexP} className={classes.product}>
                                    {product.comment} x {product.amount}
                                  </div>
                                ))}
                                <Typography
                                  textAlign="left"
                                  color="#737791"
                                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                  onClick={() => handleShowDetail(project.uuid, order.products)}
                                >
                                  {TEXT[lang].SHOW_PACKAGE_DETAILS}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack>
                              <div
                                className={classes.invoice}
                                style={{ background: STATUS_PAYMENT[order.status]?.color }}
                              >
                                {STATUS_PAYMENT[order.status].label[lang]}
                              </div>
                              <div className={classes.price} style={{ margin: '12px 0' }}>
                                <NumberFormat
                                  value={order.totalSum}
                                  suffix=" руб"
                                  decimalScale={2}
                                  displayType="text"
                                  thousandSeparator=" "
                                  decimalSeparator=","
                                />
                              </div>
                              {order.properties?.invoiceDocId && (
                                <div
                                  className={classes.viewPDF}
                                  style={{
                                    textAlign: 'left',
                                    color: isDownloading && 'grey',
                                    cursor: isDownloading && 'auto',
                                  }}
                                  onClick={() => handleDownFile(order.properties?.invoiceDocId)}
                                >
                                  «Скачать счет»
                                </div>
                              )}
                              <div className={classes.invoice} style={{ marginTop: '12px', background: 'grey' }}>
                                {TEXT[lang].ARCHIVED}
                              </div>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </div>
                  )
                );
              })}
            </Stack>
          ) : (
            <Typography variant="h6" color="text.secondary" sx={{ margin: '0 24px', textAlign: 'center' }}>
              {TEXT[lang].NO_DATA}
            </Typography>
          )}
        </Box>
      )}
    </div>
  );
};

export default ArchivedOrderPage;

import fetch from 'isomorphic-unfetch';
import { logout } from '../store/actions/auth';
import { ENDPOINT } from './constant';

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = await response.json();
  throw { ...error, code: response.status };
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function parseBlob(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.blob();
}

export function request(url, params = {}, isBlob) {
  let body = params.body ? JSON.stringify(params.body) : null;
  const isFormData = params.body && params.body instanceof FormData;
  const token = localStorage.getItem('accessToken');
  const headers = {
    'Content-Type': 'application/json',
    ...params.headers,
  };
  if (token) {
    headers.authorization = token;
  }
  if (isFormData) {
    body = params.body;
    delete headers['Content-Type'];
  }
  return fetch(process.env.REACT_APP_SERVER_URL + url, {
    ...params,
    body,
    headers,
  })
    .then(checkStatus)
    .then(
      url.includes(`${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.PASSPORT_FILE}`) ||
        url.includes(`${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.AVATAR}`) ||
        url.includes(`${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.MEMBERS}/`) ||
        isBlob
        ? parseBlob
        : parseJSON
    )
    .catch((error) => {
      if (error.statusCode === 401) {
        logout();
      }
      throw error;
    });
}

export const downloadFile = async (path, name) => {
  const blob = await fetch(`${process.env.REACT_APP_SERVER_URL}${path}`, {
    headers: { authorization: localStorage.getItem('accessToken') },
  })
    .then(checkStatus)
    .then((res) => res.blob());
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  // window.URL.revokeObjectURL(url);
};

export const viewFile = async (path) => {
  let blob = await fetch(`${process.env.REACT_APP_SERVER_URL}${path}`, {
    headers: { authorization: localStorage.getItem('accessToken') },
  })
    .then(checkStatus)
    .then((res) => res.blob());
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.download = 'invoice.pdf';
  a.click();
  // window.URL.revokeObjectURL(url);
};

import {
  AutoStories,
  Badge,
  ContentPaste,
  FmdGood,
  HomeRepairService,
  People,
  PersonOutline,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from 'src/hooks';
import { routes } from 'src/utils/routes';
import './style.sass';

function Sidebar({ callback }) {
  const navigate = useNavigate();
  const { projectUuid } = useParams();
  const location = useLocation();
  const { localizeText } = useLanguage();

  const projectSettings = useSelector((state) => state.app.projectSettings);
  const currentUser = useSelector((state) => state.auth.user);
  const mainRoleId = currentUser?.projectSettings?.[projectUuid]?.mainRoleId;
  const joinedProjects = currentUser?.projectUuids?.includes(projectUuid);

  var typeProcessBDK = projectSettings?.typeProcessBDK;
  if (typeProcessBDK == 'networking') {
    if (mainRoleId === 'e44095a7-0af4-f25b-35f8-eb160f00e036') {
      typeProcessBDK = 'networking';
    } else {
      typeProcessBDK = '';
    }
  }

  const iconSize = { height: '24px', width: '24px' };

  const PROJECT_DETAIL_TABS = {
    ABOUT: {
      key: 'about',
      title: 'О мероприятии',
      icon: <FmdGood sx={{ ...iconSize }} />,
      path: routes.projectDetail.path.replace(':projectUuid', projectUuid),
    },
    ACCREDITATION: {
      key: 'accreadition',
      title: 'Моя анкета',
      icon: <PersonOutline sx={{ ...iconSize }} />,
      path: routes.projectAccreadition.path.replace(':projectUuid', projectUuid),
      isHidden: !joinedProjects,
    },
    PARTICIPANTS: {
      key: 'participants',
      title: 'Мой список участников',
      icon: <People sx={{ ...iconSize }} />,
      path: routes.projectAccreadition.path.replace(':projectUuid', projectUuid),
      search: 'tab=members',
      isHidden: !joinedProjects,
    },
    BUSINESS_PROGRAMS: {
      key: 'business-programs',
      title: 'Деловая программа',
      icon: <ContentPaste sx={{ ...iconSize }} />,
      path: routes.projectBusinessProgram.path.replace(':projectUuid', projectUuid),
      isHidden: !joinedProjects,
    },
    BUY_TICKET: {
      key: 'buy-ticket',
      title: 'Пакеты услуг участника',
      icon: <HomeRepairService sx={{ ...iconSize }} />,
      path: routes.projectBuyTicket.path.replace(':projectUuid', projectUuid),
      isHidden: !joinedProjects,
    },
    FILES: {
      key: 'files',
      title: 'Материалы',
      icon: <AutoStories sx={{ ...iconSize }} />,
      path: routes.projectInfoFiles.path.replace(':projectUuid', projectUuid),
      isHidden: !joinedProjects,
    },
    ORDERS: {
      key: 'orders',
      title: localizeText.ORDERS,
      icon: <Badge sx={{ ...iconSize }} />,
      path: routes.myOrder.path.replace(':projectUuid', projectUuid),
      isHidden: !joinedProjects,
    },
  };

  if (typeProcessBDK !== '') {
    PROJECT_DETAIL_TABS['BDK'] = {
      key: 'bdk',
      title: typeProcessBDK === 'bdk' ? localizeText.BDK : localizeText.NETWORKING,
      icon: <Badge sx={{ ...iconSize }} />,
      path: routes.projectVizits.path.replace(':projectUuid', projectUuid),
      isHidden: !joinedProjects,
    };
  }

  const handleNavigate = (path) => () => {
    navigate(path);
    callback?.();
  };

  return projectUuid ? (
    <Stack className="sidebar" gap="8px">
      {Object.values(PROJECT_DETAIL_TABS).map((tab) => {
        const isActive = tab.search
          ? location?.pathname === tab.path && location.search?.includes(tab.search)
          : location?.pathname === tab.path && !location.search;

        return (
          !tab.isHidden && (
            <div
              className={classNames('sidebar__tab', { selected: isActive })}
              key={tab.key}
              onClick={handleNavigate(tab.path + (tab.search ? `?${tab.search}` : ''))}
            >
              <span className="sidebar__tab-icon">{tab.icon}</span>
              <span className="sidebar__tab-title">{tab.title}</span>
            </div>
          )
        );
      })}
    </Stack>
  ) : (
    <></>
  );
}

export default Sidebar;

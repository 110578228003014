import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useResponsive } from '../../hooks';
import { LanguageMuiSelect } from '../language-select';
import classes from './auth-layout.module.sass';

export function AuthLayout({ children }) {
  const isDesktop = useResponsive();

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={classes.root}
      sx={{
        backgroundImage: isDesktop ? `url(/images/auth-background.png)` : 'none',
        backgroundSize: '100% 100%',
      }}
    >
      <Grid container padding={{ xs: '20px', md: '40px' }} spacing={{ xs: 0, md: 4 }} height="100dvh" overflow="auto">
        <Grid item xs={12} md={6}>
          <Box sx={{ maxWidth: '600px', height: '100%', margin: 'auto', position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
              <LanguageMuiSelect hideLabel />
            </Box>
            <Box sx={{ height: '100%' }}>{children}</Box>
          </Box>
        </Grid>
        {isDesktop && (
          <Grid item xs={12} md={6}>
            <Stack padding="60px 0 60px 60px">
              <img src="/images/logo.png" alt="logo" width="380px" />
              <Typography
                sx={{
                  fontSize: '80px',
                  fontWeight: 200,
                  lineHeight: '91px',
                  textTransform: 'uppercase',
                  color: '#fff',
                  marginTop: '40px',
                }}
              >
                на событийной
                <br />
                высоте!
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
      {/* <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="nowrap"
      >
        <Grid
          item
          sm={12}
          md={6}
          className={classes.left}
          style={{ backgroundImage: windowSize.width < BREAKPOINTS.md &&  }}
        >
          <Header />
          {children}
          <Footer />
        </Grid>
        <Grid
          item
          md={6}
          justifyContent="center"
          alignItems="center"
          className={classes.right}
          style={{ display: 'flex', backgroundImage: `url(/images/auth-background.png)` }}
        >
          <div className={classes.language}>
            <LanguageMuiSelect />
          </div>
          <img className={classes.image} src="/images/auth-icon.png" alt="Icon" />
        </Grid>
      </Grid> */}
    </Container>
  );
}

export function useDownloadFile() {
  const downloadFile = async (blob, filename) => {
    try {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename || Date.now());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    downloadFile: (blob, filename) => downloadFile(blob, filename),
  };
}

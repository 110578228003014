import { Box, Grid, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import DocumentFile from 'src/components/document-file';
import Loader from 'src/components/loader';
import { useLanguage } from 'src/hooks';
import { userAPIs } from 'src/services';

function ProjectInfoFiles({ projectUuid }) {
  const user = useSelector((state) => state.auth.user);

  const { localizeMessage } = useLanguage();
  const { localizeText } = useLanguage();

  const fetchDocuments = async ({ queryKey }) => {
    const [, projectUuid, user] = queryKey;
    const response = await userAPIs.getDocuments({
      projectUuid,
      roleUuid: user?.projectSettings?.[projectUuid]?.mainRoleId,
      type: 'document_information',
    });
    return response.message || [];
  };

  const { isLoading, data: documents } = useQuery(['fetchDocuments', projectUuid, user], fetchDocuments, {
    enabled: !!projectUuid && !!user?.projectSettings?.[projectUuid]?.mainRoleId,
    staleTime: 0,
  });

  return (
    <Box sx={{ minHeight: '50vh' }}>
      <Stack direction="row" gap="8px" alignItems="center">
        <Box
          container
          sx={{
            display: 'flex',
            width: '100%',
            marginTop: '-2rem',
            marginBottom: '2rem',
            boxShadow: '0px 6px 16px -4px #3A35411A, 0px 2px 12px -4px #3A354114, 0px 2px 12px -4px #3A35411A',
            backgroundColor: 'white',
            padding: '20px',
          }}
        >
          <h2 style={{ margin: 0, fontWeight: 'bold' }}>{localizeText.INFORMATION_FILES}</h2>
        </Box>
      </Stack>
      {isLoading ? (
        <Loader />
      ) : documents?.length > 0 ? (
        <Grid spacing={6} container justifyContent="center">
          {documents?.map((document, index) => (
            <Grid item key={index} xs={12} md={6}>
              <DocumentFile document={document} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" color="text.secondary" sx={{ margin: '0 24px', textAlign: 'center' }}>
          {localizeMessage.NO_DATA}
        </Typography>
      )}
    </Box>
  );
}

export default memo(ProjectInfoFiles);

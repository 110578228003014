import React from 'react';
import Audio from 'src/components/audio';
import ErrorBoundaryWrapper from 'src/components/error-boundary';
import ThemeComponent from 'src/theme/ThemeComponent';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';

function AppWrapper() {
  return (
    <ThemeComponent>
      <ErrorBoundaryWrapper>
        <Audio />
        <Routes />
        <ToastContainer />
      </ErrorBoundaryWrapper>
    </ThemeComponent>
  );
}

export default AppWrapper;

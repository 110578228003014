import { Chip } from '@mui/material';
import { memo } from 'react';
import { useLanguage } from 'src/hooks';
import { LOCALIZATION } from 'src/utils/constant';

function UserRole({ role, sx, icon }) {
  const { lang } = useLanguage();

  if (!role) return null;
  return <Chip
    sx={{
      ...sx,
      '& .MuiChip-deleteIcon': {
        color: '#333',
      },
      '& .MuiChip-deleteIcon:hover': {
        color: '#333',
      },
    }}
    label={role?.name?.[lang] || role?.name?.[LOCALIZATION.ru_RU]}
    color="success"
    onClick={() => { }}
    onDelete={() => { }}
    deleteIcon={icon}

  />;
}

export default memo(UserRole);

// ** React Imports
import { Fragment, useEffect, useState } from 'react';

// ** MUI Imports
import Badge from '@mui/material/Badge';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

// ** Icon Imports
import Icon from 'src/@core/components/icon';

// ** Custom Components Import
import ChatLog from './ChatLog';
import SendMsgForm from 'src/views/apps/chat/SendMsgForm';
import CustomAvatar from 'src/@core/components/mui/avatar';
import UserProfileRight from 'src/views/apps/chat/UserProfileRight';
import { useSelector } from 'react-redux';
import { MESSAGES, TEXT } from 'src/utils/constant';
import { CalendarMonth } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Loader from 'src/components/loader';

export const START_CHAT_MESSAGE = '🤚';

// ** Styled Components
const ChatWrapperStartChat = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  display: 'flex',
  borderRadius: 1,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.action.hover,
}));

const ChatContent = (props) => {
  // ** Props
  const {
    store,
    hidden,
    sendMsg,
    mdAbove,
    dispatch,
    statusObj,
    getInitials,
    sidebarWidth,
    userProfileRightOpen,
    handleLeftSidebarToggle,
    handleUserProfileRightSidebarToggle,
  } = props;

  const lang = useSelector((state) => state.app.lang);
  const isLoadingChat = store.isLoadingChat;
  const [showMeetings, setShowMeetings] = useState(true);

  const handleStartConversation = () => {
    if (!mdAbove) {
      handleLeftSidebarToggle();
    }
  };

  const renderContent = () => {
    if (store) {
      const selectedChat = store.selectedChat;
      if (!selectedChat) {
        return (
          <ChatWrapperStartChat
            sx={{
              ...(mdAbove ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {}),
            }}
          >
            <MuiAvatar
              sx={{
                mb: 6,
                pt: 8,
                pb: 7,
                px: 7.5,
                width: 110,
                height: 110,
                boxShadow: 3,
                backgroundColor: 'background.paper',
                color: (theme) => theme.palette.text.primary,
              }}
            >
              <Icon icon="mdi:message-outline" fontSize="3.125rem" />
            </MuiAvatar>
            <Box
              onClick={handleStartConversation}
              sx={{
                py: 2,
                px: 6,
                boxShadow: 3,
                borderRadius: 5,
                backgroundColor: 'background.paper',
                cursor: mdAbove ? 'default' : 'pointer',
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: '1.125rem', lineHeight: 'normal' }}>
                {TEXT[lang].START_CHAT}
              </Typography>
            </Box>
          </ChatWrapperStartChat>
        );
      } else {
        return (
          <Box
            sx={{
              flexGrow: 1,
              width: '0',
              height: '100%',
              backgroundColor: 'action.hover',
            }}
          >
            <Box
              sx={{
                py: 3,
                px: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {mdAbove ? null : (
                  <IconButton onClick={handleLeftSidebarToggle} sx={{ mr: 2 }}>
                    <Icon icon="mdi:menu" />
                  </IconButton>
                )}
                <Box
                  onClick={handleUserProfileRightSidebarToggle}
                  sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    sx={{ mr: 3 }}
                  // badgeContent={
                  //   <Box
                  //     component="span"
                  //     sx={{
                  //       width: 8,
                  //       height: 8,
                  //       borderRadius: '50%',
                  //       color: `${statusObj[selectedChat.contact.status]}.main`,
                  //       boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}`,
                  //       backgroundColor: `${statusObj[selectedChat.contact.status]}.main`,
                  //     }}
                  //   />
                  // }
                  >
                    {selectedChat.contact.avatar ? (
                      <MuiAvatar
                        src={selectedChat.contact.avatar}
                        alt={selectedChat.contact.fullName}
                        sx={{ width: '2.75rem', height: '2.75rem' }}
                      />
                    ) : (
                      <CustomAvatar
                        skin="light"
                        color={selectedChat.contact.avatarColor}
                        sx={{ width: '2.75rem', height: '2.75rem', fontSize: '1rem' }}
                      >
                        {getInitials(selectedChat.contact.fullName)}
                      </CustomAvatar>
                    )}
                  </Badge>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                      {selectedChat.contact.fullName}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                      {selectedChat.contact.role}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* {mdAbove ? (
                  <Fragment>
                    <IconButton size="small" sx={{ color: 'text.secondary' }}>
                      <Icon icon="mdi:phone-outline" fontSize="1.25rem" />
                    </IconButton>
                    <IconButton size="small" sx={{ color: 'text.secondary' }}>
                      <Icon icon="mdi:video-outline" fontSize="1.5rem" />
                    </IconButton>
                    <IconButton size="small" sx={{ color: 'text.secondary' }}>
                      <Icon icon="mdi:magnify" fontSize="1.25rem" />
                    </IconButton>
                  </Fragment>
                ) : null}

                <OptionsMenu
                  menuProps={{ sx: { mt: 2 } }}
                  icon={<Icon icon="mdi:dots-vertical" fontSize="1.25rem" />}
                  iconButtonProps={{ size: 'small', sx: { color: 'text.secondary' } }}
                  options={['View Contact', 'Mute Notifications', 'Block Contact', 'Clear Chat', 'Report']}
                /> */}
                {store?.meetingsChat?.length > 0 && (
                  <Tooltip title={showMeetings ? MESSAGES[lang].HIDE_MEETINGS : MESSAGES[lang].SHOW_MEETINGS}>
                    <IconButton onClick={() => setShowMeetings(!showMeetings)}>
                      <CalendarMonth />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>

            {selectedChat && store.userProfile ? (
              <ChatLog
                hidden={hidden}
                data={{ ...selectedChat, userContact: store.userProfile }}
                showMeetings={showMeetings && store?.meetingsChat?.length > 0}
                onSayHi={() => {
                  if (store && store.selectedChat) {
                    dispatch(sendMsg({ ...store.selectedChat, message: START_CHAT_MESSAGE }));
                  }
                }}
                sendMsg={sendMsg}
              />
            ) : null}

            <SendMsgForm store={store} dispatch={dispatch} sendMsg={sendMsg} />

            <UserProfileRight
              store={store}
              hidden={hidden}
              statusObj={statusObj}
              getInitials={getInitials}
              sidebarWidth={sidebarWidth}
              userProfileRightOpen={userProfileRightOpen}
              handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle}
            />
          </Box>
        );
      }
    } else {
      return null;
    }
  };

  return isLoadingChat ? <Loader /> : renderContent();
};

export default ChatContent;

import React from 'react';

function YandexIcon({ height, width }) {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height, width }}
    >
      <path
        d="M0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60Z"
        fill="#FC3F1D"
      />
      <path
        d="M68.8465 33.6027H62.7742C52.3646 33.6027 47.1598 38.8075 47.1598 46.6148C47.1598 55.2895 50.6297 59.6268 58.4369 64.8316L64.5092 69.169L47.1598 96.0605H33.2803L49.7622 71.7714C40.22 64.8316 35.0152 58.7593 35.0152 47.4822C35.0152 33.6027 44.5574 24.0605 62.7742 24.0605H80.9911V96.0605H68.8465V33.6027Z"
        fill="white"
      />
    </svg>
  );
}

export default YandexIcon;

import { Close, LogoutOutlined, Menu } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import { IconButton, Stack, SwipeableDrawer as MuiSwipeableDrawer, styled, Divider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import ProfileSummary from './ProfileSummary';
import Sidebar from '../../sidebar';
import SidebarItem from '../../sidebar/components/SidebarItem';
import { useResponsive } from 'src/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { logout } from 'src/store/actions/auth';
import AtomIconButton from 'src/components/atoms/button/IconButton';
import eventBus from 'src/utils/eventBus';
import { EVENTS } from 'src/utils/constant';
import { BasketAltIcon, ChatAltIcon } from 'src/icons';
import { useSelector } from 'react-redux';
import { Badge } from 'src/components/badge';

const SwipeableDrawer = styled(MuiSwipeableDrawer)({
  overflowX: 'hidden',
  transition: 'width .25s ease-in-out',
  '& ul': {
    listStyle: 'none',
  },
  '& .MuiListItem-gutters': {
    paddingLeft: 4,
    paddingRight: 4,
  },
  '& .MuiDrawer-paper': {
    left: 'unset',
    right: 'unset',
    overflowX: 'hidden',
    transition: 'width .25s ease-in-out, box-shadow .25s ease-in-out',
  },
});

function NavigationDrawer() {
  const isDesktop = useResponsive();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectUuid } = useParams();

  const [open, setOpen] = useState(false);

  const order = useSelector((state) => state.app.order);

  const numOfPackages = useMemo(() => {
    const countPackages = (packages) => packages?.reduce((p, c) => (p += c.count), 0) ?? 0;
    if (projectUuid) {
      return countPackages(order?.find((item) => item.projectUuid === projectUuid)?.packages);
    }
    return order?.reduce((p, c) => (p += countPackages(c.packages)), 0);
  }, [projectUuid, order]);

  const numOfUnreadChat = useMemo(() => {
    return 0;
  }, []);

  const toggleNavVisibility = () => setOpen(!open);

  useEffect(() => {
    if (isDesktop) {
      setOpen(false);
    }
  }, [isDesktop]);

  const iconSize = { width: 24, height: 24 };

  const MENU_ITEMS = {
    USER_PROJECTS: {
      key: 'user_projects',
      title: 'Мои мероприятия',
      path: routes.homepage.path,
    },
    CALENDARS: {
      key: 'calendars',
      title: 'Мой календарь',
      path: routes.calendars.path,
    },
    PROJECTS: {
      key: 'projects',
      title: 'События Экспофорума',
      path: routes.projects.path,
    },
    PROFILE: {
      key: 'profile',
      title: 'Персональные данные',
      icon: <BadgeIcon sx={{ ...iconSize }} />,
      path: routes.profile.path,
    },
    LOGOUT: {
      key: 'logout',
      title: 'Выйти',
      icon: <LogoutOutlined sx={{ ...iconSize }} />,
      onClick: () => {
        logout();
      },
    },
  };

  const renderMenuItem = (tab) => {
    const isActive = location.pathname === tab.path;
    return (
      <SidebarItem
        isActive={isActive}
        tab={tab}
        onClick={() => {
          tab.onClick ? tab.onClick() : navigate(tab.path);
          setOpen(false);
        }}
      />
    );
  };

  const renderBasketButton = () => {
    return (
      <AtomIconButton
        color="blue"
        onClick={() => {
          if (projectUuid) {
            navigate(routes.orderDetail.path.replace(':projectUuid', projectUuid));
          } else {
            eventBus.emit(EVENTS.OPEN_SELECT_PROJECT_MODAL, {
              onSelect: (projectUuid) => {
                navigate(routes.orderDetail.path.replace(':projectUuid', projectUuid));
              },
            });
          }
        }}
      >
        <BasketAltIcon />
      </AtomIconButton>
    );
  };

  const renderChatButton = () => {
    return (
      <AtomIconButton
        color="orange"
        onClick={() => {
          if (projectUuid) {
            navigate(routes.projectChat.path.replace(':projectUuid', projectUuid));
          } else {
            eventBus.emit(EVENTS.OPEN_SELECT_PROJECT_MODAL, {
              onSelect: (projectUuid) => {
                navigate(routes.projectChat.path.replace(':projectUuid', projectUuid));
              },
            });
          }
        }}
      >
        <ChatAltIcon />
      </AtomIconButton>
    );
  };

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton onClick={() => setOpen(true)}>
        <Menu sx={{ fontSize: 28 }} />
      </IconButton>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { minHeight: '100dvh', width: '100dvw' } }}
      >
        <Stack paddingX="20px" sx={{ height: '100%', overflow: 'hidden' }}>
          <Stack paddingY="20px" gap="16px" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap="16px">
              <ProfileSummary />
              <IconButton onClick={toggleNavVisibility}>
                <Close />
              </IconButton>
            </Stack>

            <Stack direction="row" gap="16px">
              {numOfUnreadChat > 0 ? (
                <Badge id="chat" badgeContent={numOfUnreadChat}>
                  {renderChatButton()}
                </Badge>
              ) : (
                renderChatButton()
              )}
              {numOfPackages > 0 ? (
                <Badge id="shopping-cart" badgeContent={numOfPackages}>
                  {renderBasketButton()}
                </Badge>
              ) : (
                renderBasketButton()
              )}
            </Stack>
          </Stack>
          <Stack paddingBottom="20px" gap="16px" sx={{ flex: '1 1 auto', height: '100%', overflow: 'auto' }}>
            {/* Navigation global */}
            <Stack gap="8px">
              {renderMenuItem(MENU_ITEMS.USER_PROJECTS)}
              {/* {renderMenuItem(MENU_ITEMS.CALENDARS)} */}
              {renderMenuItem(MENU_ITEMS.PROJECTS)}
            </Stack>

            {/* Navigation sidebar */}
            {projectUuid && (
              <>
                <Divider />
                <Sidebar
                  callback={() => {
                    setOpen(false);
                  }}
                />
              </>
            )}

            {/* Profile Menu */}
            <Divider />
            <Stack gap="8px">
              {renderMenuItem(MENU_ITEMS.PROFILE)}
              {renderMenuItem(MENU_ITEMS.LOGOUT)}
            </Stack>
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </Stack>
  );
}

export default NavigationDrawer;

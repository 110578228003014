import { ConfirmModal, MainLayout } from 'src/components';

// ** React Imports
import { useEffect, useState } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// ** Store & Actions Imports
import { useDispatch, useSelector } from 'react-redux';
import {
  sendMsg,
  selectChat,
  fetchUserProfile,
  fetchChatsContacts,
  removeSelectedChat,
  fetchUserVizit,
  fetchMeetingsInChat,
} from 'src/store/apps/chat';

// ** Hooks
import { useSettings } from 'src/@core/hooks/useSettings';

// ** Utils Imports
import { getInitials } from 'src/@core/utils/get-initials';
import { formatDateToMonthShort } from 'src/@core/utils/format';

// ** Chat App Components Imports
import SidebarLeft from 'src/views/apps/chat/SidebarLeft';
import ChatContent from 'src/views/apps/chat/ChatContent';

import queryString from 'query-string';
import { getProjectsList } from 'src/store/actions/app';
import { EVENTS, TEXT } from 'src/utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import ScheduleMeetingModal from 'src/components/schedule-meeting-modal';
import { ConfirmAppointmentModal } from 'src/components/confirm-appoinment-modal';
import { useProjectSettings } from 'src/hooks';
import { Button } from '@mui/material';
import eventBus from 'src/utils/eventBus';

function ProjectChatPage() {
  const { projectUuid } = useParams();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.app.lang);
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);

  const breadcrumbs = [
    { path: '/', title: TEXT[lang].MAIN_PAGE },
    { path: null, title: project?.localization?.[lang].shortName },
  ];

  useProjectSettings({ projectUuid });

  useEffect(() => {
    const fetchData = async () => {
      const projects = await getProjectsList(queryString.stringify({ uuid: projectUuid }), false);
      setProject(projects?.[0]);
      setIsLoading(false);
    };

    fetchData();
    dispatch(fetchUserVizit(projectUuid)).then((action) => {
      if (!action.payload.userVizit) {
        navigate(routes.projectVizits.path.replace(':projectUuid', projectUuid) + '?tab=business-card');
      }
    });
  }, [projectUuid]);

  useEffect(() => {
    removeSelectedChat();
  }, []);

  // ** States
  const [userStatus, setUserStatus] = useState('online');
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const [userProfileLeftOpen, setUserProfileLeftOpen] = useState(false);
  const [userProfileRightOpen, setUserProfileRightOpen] = useState(false);

  // ** Hooks
  const theme = useTheme();
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const hidden = useMediaQuery(theme.breakpoints.down('lg'));
  const store = useSelector((state) => state.chat);

  // ** Vars
  const { skin } = settings;
  const smAbove = useMediaQuery(theme.breakpoints.up('sm'));
  const sidebarWidth = smAbove ? 370 : 300;
  const mdAbove = useMediaQuery(theme.breakpoints.up('md'));

  const statusObj = {
    busy: 'error',
    away: 'warning',
    online: 'success',
    offline: 'secondary',
  };
  useEffect(() => {
    if (store?.userMemberVizits) {
      dispatch(fetchChatsContacts());
    }
  }, [dispatch, store?.userMemberVizits]);
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [user]);
  useEffect(() => {
    if (store.selectedChat?.chat?.uuid) {
      dispatch(fetchMeetingsInChat({ projectUuid, chatUuid: store.selectedChat.chat.id }));
    }
  }, [store.selectedChat?.chat?.uuid]);

  const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen);
  const handleUserProfileLeftSidebarToggle = () => setUserProfileLeftOpen(!userProfileLeftOpen);
  const handleUserProfileRightSidebarToggle = () => setUserProfileRightOpen(!userProfileRightOpen);

  return (
    <MainLayout breadcrumbs={breadcrumbs} fullHeight={true} contentHeightFixed={true}>
      <Box
        className="app-chat"
        sx={{
          width: '100%',
          display: 'flex',
          borderRadius: { xs: 0, md: 4 },
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'background.paper',
          boxShadow: skin === 'bordered' ? 0 : 6,
          ...(skin === 'bordered' && { border: `1px solid ${theme.palette.divider}` }),
          marginTop: { xs: 0, md: '2rem' },
          height: 'calc(100dvh - 250px)',
        }}
      >
        <SidebarLeft
          store={store}
          hidden={hidden}
          mdAbove={mdAbove}
          dispatch={dispatch}
          statusObj={statusObj}
          userStatus={userStatus}
          selectChat={selectChat}
          getInitials={getInitials}
          sidebarWidth={sidebarWidth}
          setUserStatus={setUserStatus}
          leftSidebarOpen={leftSidebarOpen}
          removeSelectedChat={removeSelectedChat}
          userProfileLeftOpen={userProfileLeftOpen}
          formatDateToMonthShort={formatDateToMonthShort}
          handleLeftSidebarToggle={handleLeftSidebarToggle}
          handleUserProfileLeftSidebarToggle={handleUserProfileLeftSidebarToggle}
        />
        <ChatContent
          store={store}
          hidden={hidden}
          sendMsg={sendMsg}
          mdAbove={mdAbove}
          dispatch={dispatch}
          statusObj={statusObj}
          getInitials={getInitials}
          sidebarWidth={sidebarWidth}
          userProfileRightOpen={userProfileRightOpen}
          handleLeftSidebarToggle={handleLeftSidebarToggle}
          handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle}
        />
      </Box>
      <ScheduleMeetingModal projectUuid={projectUuid} />
      <ConfirmAppointmentModal projectUuid={projectUuid} />
      <ConfirmModal />
    </MainLayout>
  );
}

export default ProjectChatPage;

import SearchIcon from '@mui/icons-material/Search';
import { Box, Container, InputAdornment, Tab, Tabs, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { a11yProps, MainLayout, TabPanel } from '../../components';
import classes from './faqs.module.sass';
import { LOCALIZATION, TEXT } from '../../utils/constant';

//import AccessibilityQuestions from './questions/accessibility.json';
//import AccountQuestions from './questions/account.json';
//import DevelopmentQuestions from './questions/development.json';
//import MarketingQuestions from './questions/marketing.json';
import PopularQuestions from './questions/popular.json';

function FAQsPage() {
  const [tab, setTab] = useState(0);
  const [searchText, setSearchText] = useState('');
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  //const data = [PopularQuestions, AccessibilityQuestions, MarketingQuestions, DevelopmentQuestions, AccountQuestions];
  //const tabs = ['Популярные вопросы', 'Accessibility', 'Marketing', 'Development', 'Account'];
  const data = [PopularQuestions];
  const tabs = ['Популярные вопросы'];

  const handleChangeSearch = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <MainLayout breadcrumbs={[]}>
      <Container maxWidth="md" className={classes.root}>
        <h1>👋 {TEXT[lang].HOW_WE_CAN_HELP} </h1>
        <div className={classes.search}>
          <TextField
            size="small"
            variant="outlined"
            onChange={handleChangeSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            sx={{
              '& .MuiTabs-scroller': { overflow: 'auto !important' },
              '& .MuiTab-root': {
                paddingLeft: '0 !important',
                textTransform: 'capitalize !important',
                alignItems: 'flex-start !important',
                minWidth: 'auto !important',
                width: 'fit-content !important',
              },
              '& .Mui-selected': { color: '#737791 !important' },
              '& .MuiTabs-indicator': { display: 'none' },
            }}
          >
            {tabs.map((tab, i) => (
              <Tab key={i} label={tab} {...a11yProps(i)} />
            ))}
          </Tabs>
        </Box>
        {tabs.map((_tab, i) => (
          <TabPanel key={i} value={tab} index={i}>
            <h2 className={classes.title}>{_tab} </h2>
            {data?.[i]
              ?.filter((r) => (searchText ? r.question.toLowerCase().includes(searchText.toLowerCase()) : true))
              ?.map((row, _i) => (
                <div key={_i} className={classes.row}>
                  <h2 className={classes.question}>
                    <svg className={classes.icon} viewBox="0 0 16 16">
                      <path fill="#73779173" d="M4 8H0v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2V8z" />
                      <path
                        fill="#737791"
                        d="M15 1H7c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H13c1.7 0 3-1.3 3-3V2c0-.6-.4-1-1-1z"
                      />
                    </svg>
                    {row.question}
                  </h2>
                  <div className={classes.answer}>{row.answer}</div>
                  <ul className={classes.topic}>
                    {row.topics?.map((topic, topicIndex) => (
                      <li key={topicIndex}>{topic}</li>
                    ))}
                  </ul>
                </div>
              ))}
          </TabPanel>
        ))}
      </Container>
    </MainLayout>
  );
}

export default FAQsPage;

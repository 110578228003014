import { CircularProgress } from '@mui/material';

export default function Loader({ size, sx }) {
  return (
    <CircularProgress
      size={size}
      sx={{ display: 'block', margin: 'auto', color: (theme) => theme.palette.primary.main, ...(sx || {}) }}
    />
  );
}

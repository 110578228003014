import { ChevronLeft } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import Meetings from '../../../components/meetings';
import { LOCALIZATION, TEXT } from '../../../utils/constant';
import classes from './meetings.module.sass';

function MeetingsPage({ projectUuid, vizits, setTab }) {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  return (
    <Box className={classes.content}>
      <div className={classes.header}>
        <IconButton onClick={() => setTab(null)}>
          <ChevronLeft />
        </IconButton>
        <h2 className={classes.title}>{TEXT[lang].MEETINGS}</h2>
      </div>
      <Meetings projectUuid={projectUuid} vizits={vizits} />
    </Box>
  );
}

export default MeetingsPage;

import { Link } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { documentAPIs } from 'src/services';
import { CONSENT_PERSONAL_DATA_NAME, DOCUMENT_TYPES, INTERNAL_DOCUMENTS } from 'src/utils/constant';

function AnketaPersonalField({ projectUuid }) {
  const [currentFile, setCurrentFile] = useState(null);
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentFileURL = useMemo(() => {
    if (!currentFile) return '';
    const urlCreator = window.URL || window.webkitURL;
    const fileURL = urlCreator.createObjectURL(currentFile);
    return fileURL;
  }, [currentFile]);

  useEffect(() => {
    if (!isInit && projectUuid) {
      const fetchDocument = async () => {
        try {
          setIsLoading(true);
          const res = await documentAPIs.getDocuments({ type: DOCUMENT_TYPES.CONSENT_PERSONAL_DATA, projectUuid });
          const document = res?.message?.[0];
          if (document) {
            const resBlob = await documentAPIs.getDocumentFile(res.message[0].uuid);
            if (resBlob) {
              resBlob.name = CONSENT_PERSONAL_DATA_NAME;
              setCurrentFile(resBlob);
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsInit(true);
          setIsLoading(false);
        }
      };
      fetchDocument();
    }
  }, [isInit, projectUuid]);

  return (
    <Link
      href={currentFileURL || INTERNAL_DOCUMENTS.AGREEMENT}
      target="_blank"
      sx={{ cursor: isLoading ? 'progress' : 'pointer', textDecoration: 'underline' }}
      onClick={(e) => {
        if (isLoading) {
          e.preventDefault();
        }
      }}
    >
      {CONSENT_PERSONAL_DATA_NAME}
    </Link>
  );
}

export default AnketaPersonalField;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from 'src/hooks';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';

function SelectProjectModal() {
  const { localizeText } = useLanguage();

  const userProjects = useSelector((state) => state.auth.projects);

  const [state, setState] = useState({
    show: false,
    onSelect: () => {},
  });

  const handleShow = useCallback((data) => {
    setState((old) => ({ ...old, ...data, show: true }));
  }, []);

  const handleCancel = () => {
    setState((old) => ({ ...old, show: false }));
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_SELECT_PROJECT_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_SELECT_PROJECT_MODAL, handleShow);
    };
  }, [handleShow]);

  return (
    <Dialog maxWidth="lg" color="warning" open={state.show} onClose={handleCancel}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" gap={2}>
          <span>Выбрать проект</span>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap="8px">
          {userProjects.map((project) => (
            <Button
              key={project.uuid}
              variant="outlined"
              color="primary"
              sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
              onClick={() => state.onSelect?.(project.uuid)}
            >
              {project.name}
            </Button>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '0 1.5rem 1.5rem' }}>
        <Button variant="outlined" onClick={handleCancel}>
          {localizeText.CANCEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectProjectModal;

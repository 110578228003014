import classNames from 'classnames';
import './button.sass';

function AtomButton({ color, type = 'button', children, onClick }) {
  const colorClassMapping = {
    primary: 'primary',
    secondary: 'secondary',
    selected: 'selected',
  };

  return (
    <button type={type} className={classNames('atom-button', colorClassMapping[color])} onClick={onClick}>
      {children}
    </button>
  );
}

export default AtomButton;

import { Stack, Typography } from '@mui/material';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userAPIs } from '../../services';
import { EVENTS, LOCALIZATION, MEETING_STATUS, MESSAGES, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import Loader from '../loader';
import classes from './meetings.module.sass';
import MeetingsTable from './MeetingsTable';

function Meetings({ projectUuid, meetingStatus }) {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const user = useSelector((state) => state.auth.user);
  const { showTableNumber } = useSelector((state) => state.app.projectSettings || {});
  const [isLoading, setIsLoading] = useState(true);
  const [userMeetings, setUserMeetings] = useState([]);
  const [meetingsToUser, setMeetingsToUser] = useState([]);
  const location = useLocation();
  const queryParams = qs.parse(location.search);


  const focusMeetingUuid = queryParams?.meetingUuid;

  const getAvatarMember = async (memberUuid) => {
    try {
      const rawAvatar = await userAPIs.getAvatarMemberVizits(memberUuid);
      return rawAvatar;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  const fetchMeetings = async () => {
    try {
      const res = await userAPIs.getUserMeetings(projectUuid, meetingStatus);
      const rawMeetings = res.message || [];
      let avatars = [];
      for (let i = 0; i < rawMeetings.length; i++) {
        const rawAvatar = await getAvatarMember(rawMeetings[i].avatar);
        avatars.push(rawAvatar);
      }
      let _meetingsToUser = [];
      let _userMeetings = [];
      rawMeetings.forEach((m, i) => {
        const _meeting = { ...m, avatar: avatars[i] };
        if (m.ownerUuid === user.uuid) {
          _userMeetings.push(_meeting);
        } else {
          _meetingsToUser.push(_meeting);
        }
      });
      setMeetingsToUser(_meetingsToUser);
      setUserMeetings(_userMeetings);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchMeetings();
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const updateMeetingStatus = async (meetingUuid, status) => {
    try {
      await userAPIs.updateMeetingStatus(projectUuid, meetingUuid, { status });
      setMeetingsToUser((old) => old.map((m) => (m.uuid === meetingUuid ? { ...m, status } : m)));
      createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    }
  };

  const handleDeclineMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        updateMeetingStatus(meeting.uuid, MEETING_STATUS.declined[LOCALIZATION.en_US]);
      },
    });
  };

  const handleApproveMeeting = (meeting) => {
    const duplicateApprovedMeeting = userMeetings.concat(meetingsToUser).some((baseMeeting) => {
      return (
        baseMeeting.uuid !== meeting.uuid &&
        baseMeeting.status === MEETING_STATUS.approved[LOCALIZATION.en_US] &&
        ((meeting.startTime > baseMeeting.startTime && meeting.startTime < baseMeeting.endTime) ||
          (meeting.endTime > baseMeeting.startTime && meeting.endTime < baseMeeting.endTime) ||
          (meeting.startTime < baseMeeting.startTime && meeting.endTime > baseMeeting.endTime) ||
          (meeting.startTime === baseMeeting.startTime && meeting.endTime === baseMeeting.endTime))
      );
    });
    if (duplicateApprovedMeeting) {
      eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
        content: MESSAGES[lang].CHECK_APPROVE_MEETING,
        onOk: () => {
          updateMeetingStatus(meeting.uuid, MEETING_STATUS.approved[LOCALIZATION.en_US]);
        },
      });
    } else {
      updateMeetingStatus(meeting.uuid, MEETING_STATUS.approved[LOCALIZATION.en_US]);
    }
  };
  console.log({ meetingsToUser });

  return isLoading ? (
    <Loader />
  ) : meetingsToUser.length === 0 && userMeetings.length === 0 ? (
    <Typography sx={{ fontSize: 18, textAlign: 'center' }}>{MESSAGES[lang].DO_NOT_HAVE_APPOINTMENTS}</Typography>
  ) : (
    <>
      {meetingsToUser.length > 0 && (
        <Stack className={classes.content}>
          <Typography variant="h6" marginBottom="1rem">
            {TEXT[lang].MEETINGS_TO_USER}
          </Typography>
          {/* {renderMeeting(meetingsToUser, true)} */}
          <MeetingsTable
            rows={meetingsToUser}
            total={meetingsToUser.length}
            isSelf={true}
            focusMeetingUuid={focusMeetingUuid}
            onDeclineMeeting={handleDeclineMeeting}
            onApproveMeeting={handleApproveMeeting}
          />
        </Stack>
      )}
      {userMeetings.length > 0 && (
        <Stack className={classes.content}>
          <Typography variant="h6" marginBottom="1rem">
            {TEXT[lang].YOUR_MEETINGS}
          </Typography>
          {/* {renderMeeting(userMeetings)} */}
          <MeetingsTable rows={userMeetings} total={userMeetings.length} focusMeetingUuid={focusMeetingUuid} />
        </Stack>
      )}
    </>
  );
}

export default Meetings;

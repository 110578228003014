import { CardContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { projectsAPIs } from '../../services';
import Content from './components/content';

const BusinessProgramPage = () => {
  const navigate = useNavigate();

  const { projectUuid: id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rawRes = await projectsAPIs.getProgramsOfProject(id);
        setPrograms(rawRes.message || []);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const isJoinedProject = user?.projectUuids?.includes(id);
    if (!isJoinedProject) {
      navigate(`/projects/${id}`);
    }
  }, [user]);

  if (isLoading)
    return (
      <CardContent>
        <Loader />
      </CardContent>
    );
  return <Content projectUuid={id} programs={programs} />;
};

export default BusinessProgramPage;

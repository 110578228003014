export function BadgeIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 3.5H7.5V2C7.5 1.45 7.05 1 6.5 1H5.5C4.95 1 4.5 1.45 4.5 2V3.5H2C1.45 3.5 1 3.95 1 4.5V10C1 10.55 1.45 11 2 11H10C10.55 11 11 10.55 11 10V4.5C11 3.95 10.55 3.5 10 3.5ZM4.5 6C4.915 6 5.25 6.335 5.25 6.75C5.25 7.165 4.915 7.5 4.5 7.5C4.085 7.5 3.75 7.165 3.75 6.75C3.75 6.335 4.085 6 4.5 6ZM6 9H3V8.625C3 8.125 4 7.875 4.5 7.875C5 7.875 6 8.125 6 8.625V9ZM6.5 4.5H5.5V2H6.5V4.5ZM9 8.25H7V7.5H9V8.25ZM9 6.75H7V6H9V6.75Z"
        fill="#544F5A"
      />
    </svg>
  );
}

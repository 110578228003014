import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { createNotification } from 'src/utils/notifications';
import { WEBSITE_BASE_URL } from 'src/utils/constant';
import { useSelector } from 'react-redux';
import { useLanguage } from 'src/hooks';

function InvitationModal({ userInvitations, onClose }) {
  const handleCopyLink = (link) => {
    try {
      navigator.clipboard.writeText(link);
      createNotification('Скопировано в буфер обмена', 'success');
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    }
  };

  const roles = useSelector((state) => state.app.roles);
  const rolesById = useMemo(() => {
    return roles?.reduce((p, c) => ({ ...p, [c.id]: c }), {});
  }, [roles]);

  const { getValueByLang } = useLanguage();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Ссылки-приглашения</DialogTitle>
      <DialogContent>
        <Stack sx={{ minWidth: { xs: '100%', md: '500px' } }} gap="16px">
          {Object.keys(userInvitations || {}).map((key) => {
            const link = WEBSITE_BASE_URL + '/register?invitation_id=' + userInvitations[key]?.[0]?.uuid;
            return (
              <Stack key={key} direction="row" justifyContent="space-between" alignItems="center" gap="16px">
                <Typography>{getValueByLang(rolesById[key], 'name')}</Typography>
                <Typography
                  sx={{
                    width: '100%',
                    height: '40px',
                    lineHeight: '40px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    border: '1px solid rgb(216 216 216)',
                    padding: '0 16px',
                  }}
                >
                  {link}
                </Typography>
                <IconButton
                  onClick={() => {
                    handleCopyLink(link);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InvitationModal;

import classNames from 'classnames';
import React from 'react';

function SidebarItem({ isActive, tab, onClick }) {
  if (!tab) return null;
  return (
    <div className={classNames('sidebar__tab', { selected: isActive })} key={tab.key} onClick={onClick}>
      {tab.icon && <span className="sidebar__tab-icon">{tab.icon}</span>}
      <span className="sidebar__tab-title">{tab.title}</span>
    </div>
  );
}

export default SidebarItem;

import { yupResolver } from '@hookform/resolvers/yup';
import { CheckCircle, ClearOutlined } from '@mui/icons-material';
import {
  Button as MuiButton,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UploadIcon } from '../../icons';
import {
  countScanning,
  deletePassportFile,
  getCurrentUserPassportData,
  updatePassportData,
  uploadPassportFile,
} from '../../store/actions/auth';
import { EVENTS, IMAGE_FILE_TYPES, LOCALIZATION, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { passportValidationSchema } from '../../utils/formValidator';
import classes from './passport-form.module.sass';
import Button from '../button';
import Loader from '../loader';

let fetchPassportInterval;
const MAX_SCANNING_COUNT = 12;

export function PassportForm({ isLocked }) {
  const passport = useSelector((state) => state.auth.passport);
  const scanningCount = useSelector((state) => state.auth.scanningCount);
  const countries = useSelector((state) => state.app.countries);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const [isLoading, setIsLoading] = useState(true);
  const [showUploadZone, setShowUploadZone] = useState(true);
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorUploading, setErrorUploading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScanning, setIsScanning] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: useMemo(
      () => ({
        nationality: passport?.properties?.nationality ?? '',
        document_number: passport?.properties?.document_number ?? '',
        birth_date: passport?.properties?.birth_date ?? '',
        issued_by: passport?.properties?.issued_by ?? '',
      }),
      [passport]
    ),
    resolver: yupResolver(passportValidationSchema(lang)),
  });

  const resetScanningPassport = () => {
    clearInterval(fetchPassportInterval);
    fetchPassportInterval = null;
    setIsScanning(false);
    countScanning(true);
  };

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      await updatePassportData({ ...values, nationality: parseInt(values.nationality) });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = async (file) => {
    try {
      setIsUploading(true);
      setErrorUploading(false);
      setUploadFile(file);
      const response = await uploadPassportFile(file);
      setShowUploadZone(false);
      setIsScanning(true);
      countScanning();
      setTimeout(() => {
        if (!response) {
          setErrorUploading(true);
        }
        getCurrentUserPassportData();
        fetchPassportInterval = setInterval(() => {
          getCurrentUserPassportData(false);
        }, 10000);
      }, 10000);
    } finally {
      setTimeout(() => {
        setIsUploading(false);
      }, 10000);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getCurrentUserPassportData();
      if (!response) {
        return;
      }
      setUploadFile(new File([response], 'passport.png'));
      if (scanningCount !== 0 && scanningCount < MAX_SCANNING_COUNT && !fetchPassportInterval) {
        fetchPassportInterval = setInterval(() => {
          getCurrentUserPassportData(false);
        }, 10000);
        setIsScanning(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFile = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        if (passport) {
          deletePassportFile();
        }
        setShowUploadZone(true);
        setUploadFile(null);
        reset();
      },
    });
  };

  const handleOpenFile = () => {
    const file = URL.createObjectURL(uploadFile);
    eventBus.emit(EVENTS.OPEN_VIEW_IMAGE_MODAL, { images: [{ src: file }] });
  };

  const handleCancelScanPassport = (e) => {
    e.preventDefault();
    e.stopPropagation();
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        clearInterval(fetchPassportInterval);
        setIsScanning(false);
        if (passport) {
          deletePassportFile();
        }
        setShowUploadZone(true);
        setUploadFile(null);
        reset();
      },
    });
  };

  useEffect(() => {
    const subscription = watch(() => setIsChanged(true));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    fetchData();
    return () => {
      clearInterval(fetchPassportInterval);
      setIsScanning(false);
    };
  }, []);

  useEffect(() => {
    if (scanningCount !== 0 && scanningCount === MAX_SCANNING_COUNT && fetchPassportInterval) {
      resetScanningPassport();
    }
  }, [fetchPassportInterval, scanningCount]);

  useEffect(() => {
    if (passport) {
      const properties = passport.properties;
      if (properties?.document_number && properties?.nationality && properties?.birth_date && properties?.issued_by) {
        resetScanningPassport();
      }
      setValue('document_number', properties?.document_number);
      setValue('nationality', properties?.nationality);
      setValue('birth_date', properties?.birth_date);
      setValue('issued_by', properties?.issued_by);
      setIsChanged(false);
      setShowUploadZone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passport]);

  const labelStyle = { fontSize: 17 };
  return (
    <>
      <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.top}>
          <Typography className={classes.title}>{TEXT[lang].PASSPORT_DATA}:</Typography>
          {uploadFile && !isScanning && (
            <div className={classes.file}>
              <div className={classes.fileInfo}>
                {isUploading ? <CircularProgress className={classes.icon} /> : <CheckCircle className={classes.icon} />}
                {/* <span className={classes.name}>{uploadFile.name}</span> */}
                <span className={classes.name} onClick={handleOpenFile}>
                  passport.png
                </span>
                <IconButton disabled={isLocked} onClick={handleDeleteFile}>
                  <ClearOutlined />
                </IconButton>
              </div>
              {(errorUploading || (!isUploading && !passport?.isRecognizedDoc)) && (
                <Typography color="red">{TEXT[lang].IMPOSSIBILITY_OF_RECOGNITION}</Typography>
              )}
              {isUploading && <span>{TEXT[lang].PASSPORT_PROCESSING}</span>}
            </div>
          )}
        </div>

        {isLoading ? (
          <Loader sx={{ margin: '2rem auto' }} />
        ) : (
          <>
            {showUploadZone ? (
              <FileUploader
                types={IMAGE_FILE_TYPES}
                children={
                  <div className={classes.uploadZone}>
                    <div>
                      <UploadIcon />
                    </div>
                    <Typography color="#262626" align="center" fontStyle={{ fontSize: '1.2rem' }}>
                      {TEXT[lang].UPLOAD_SCAN_IDENTITY_DOCUMENT}
                    </Typography>
                    <Typography color="#8A8A8E">{TEXT[lang].SINGLE_OR_BULK_UPLOAD_SUPPORT}</Typography>
                  </div>
                }
                handleChange={handleChange}
                name="file"
              />
            ) : (
              <>
                {isUploading || isScanning ? (
                  <>
                    <div className={classes.loadingCenter}>
                      <Loader />
                      <p className={classes.primaryText}>{TEXT[lang].SCANNING_PASSPORT}</p>
                      <div className={classes.secondaryText}>{TEXT[lang].WAIT_FEW_SECONDS}</div>
                    </div>
                    <Button
                      sx={{
                        width: 'fit-content',
                        margin: 'auto',
                        padding: '1rem 2.5rem !important',
                      }}
                      onClick={handleCancelScanPassport}
                    >
                      Отмена
                    </Button>
                  </>
                ) : (
                  <>
                    <Controller
                      name="document_number"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          label={TEXT[lang].PASSPORT_NUMBER}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              '& .MuiInputLabel-asterisk': {
                                color: 'red',
                              },
                            },
                            style: labelStyle,
                          }}
                          variant="standard"
                          value={value}
                          onChange={onChange}
                          error={errors.document_number ? true : false}
                          helperText={errors.document_number?.message}
                          disabled={isLocked}
                        />
                      )}
                    />
                    <Controller
                      name="birth_date"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          type="date"
                          label={TEXT[lang].DATE_OF_BIRTH}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              '& .MuiInputLabel-asterisk': {
                                color: 'red',
                              },
                            },
                            style: labelStyle,
                          }}
                          variant="standard"
                          value={value}
                          onChange={onChange}
                          error={errors.birth_date ? true : false}
                          helperText={errors.birth_date?.message}
                          disabled={isLocked}
                        />
                      )}
                    />
                    <Controller
                      name="nationality"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <FormControl margin="normal" error={errors.nationality ? true : false} disabled={isLocked}>
                            <InputLabel
                              required
                              shrink={true}
                              variant="standard"
                              sx={{
                                '& .MuiInputLabel-asterisk': {
                                  color: 'red',
                                },
                                fontSize: 17,
                              }}
                            >
                              {TEXT[lang].CITIZENSHIP}
                            </InputLabel>
                            <Select margin="dense" onChange={onChange} value={value} variant="standard">
                              {countries.map((country) => (
                                <MenuItem key={country.code} value={country.code}>
                                  {country.localization?.[lang]?.name}
                                  {country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.nationality && (
                              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                                {errors.nationality.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </>
                      )}
                    />
                    <Controller
                      name="issued_by"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          required
                          label={TEXT[lang].ISSUED_BY}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              '& .MuiInputLabel-asterisk': {
                                color: 'red',
                              },
                            },
                            style: labelStyle,
                          }}
                          variant="standard"
                          value={value}
                          onChange={onChange}
                          error={errors.issued_by ? true : false}
                          helperText={errors.issued_by?.message}
                          disabled={isLocked}
                        />
                      )}
                    />
                    <div className={classes.button}>
                      <MuiButton
                        type="submit"
                        className={classes.submitButton}
                        variant="contained"
                        size="large"
                        disabled={isSubmitting || !isChanged || isLocked}
                      >
                        {TEXT[lang].SAVE_CHANGES}
                      </MuiButton>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </form>
    </>
  );
}

import { Avatar, Breadcrumbs as MuiBreadcrumbs, IconButton, Link, Skeleton, Typography, Box } from '@mui/material';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { MenuIcon } from '../../icons';
import classes from './breadcrumbs.module.sass';

export function Breadcrumbs({ breadcrumbs, className, onToggleMenu }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const isLoadingAvatar = useSelector((state) => state.auth.isLoadingAvatar);

  const handleGotoProfile = () => navigate(routes.profile.path);

  return (
    <div className={classNames(classes.root, className)}>
      <MuiBreadcrumbs
        className={classes.breadcrumbs}
        aria-label="breadcrumb"
        separator={
          <Box sx={{ height: '3px', width: '3px', borderRadius: '100%', backgroundColor: '#737791', marginX: '4px' }} />
        }
        sx={{ color: '#ADADAF' }}
      >
        {breadcrumbs.map((item, i) =>
          item.path ? (
            <Link
              key={i}
              underline="hover"
              href={item.path}
              sx={{ fontSize: '12px', lineHeight: '18px', color: '#737791 !important' }}
            >
              {item.title}
            </Link>
          ) : (
            <Typography key={i} sx={{ fontSize: '12px', lineHeight: '18px', color: '#D3D3D3 !important' }}>
              {item.title}
            </Typography>
          )
        )}
      </MuiBreadcrumbs>
      {/* <div className={classes.headerMobile}>
        <IconButton onClick={onToggleMenu}>
          <MenuIcon />
        </IconButton>
        <IconButton onClick={handleGotoProfile}>
          {isLoadingAvatar ? (
            <Skeleton variant="circular" sx={{ background: '#b9b9b9 !important' }} className={classes.avatar} />
          ) : (
            <Avatar alt="avatar" className={classes.avatar} component="div" src={user?.avatar}>
              {user.lastName?.[0] || '' + user.firstName?.[0] || ''}
            </Avatar>
          )}
        </IconButton>
      </div> */}
    </div>
  );
}

import { ENDPOINT } from '../utils/constant';
import { request } from '../utils/request';

export const projectsAPIs = {
  getProjects: (query) =>
    request(`${ENDPOINT.PROJECTS.BASE}${query ? '?' + query + '&public=true' : '?public=true'}`, {
      method: 'GET',
    }),
  joinProject: (id, roleUuid) =>
    request(`${ENDPOINT.PROJECTS.BASE}/${id}${ENDPOINT.PROJECTS.JOIN}`, {
      method: 'POST',
      body: { roleUuid },
    }),
  leaveProject: (id) =>
    request(`${ENDPOINT.PROJECTS.BASE}/${id}${ENDPOINT.PROJECTS.LEAVE}`, {
      method: 'POST',
    }),
  getProjectPackages: (id, query) =>
    request(
      `${ENDPOINT.PROJECTS.INTERNAL}${ENDPOINT.PROJECTS.BASE}/${id}${ENDPOINT.PROJECTS.PACKAGES}${
        query ? '?' + query : ''
      }`,
      {
        method: 'GET',
      }
    ),
  getServicesByPackage: (projectUuid, query) =>
    request(
      `${ENDPOINT.PROJECTS.INTERNAL}${ENDPOINT.PROJECTS.BASE}/${projectUuid}${ENDPOINT.PROJECTS.PACKAGES}${
        ENDPOINT.PROJECTS.SERVICES
      }${query ? '?' + query : ''}`,
      {
        method: 'GET',
      }
    ),
  getPhotosOfProject: (id) =>
    request(`${ENDPOINT.PROJECTS.BASE}/${id}${ENDPOINT.PROJECTS.PHOTOS}`, {
      method: 'GET',
    }),
  getProgramsOfProject: (projectUuid) =>
    request(`/projects/${projectUuid}/programs`, {
      method: 'GET',
    }),
  joinProgram: (projectUuid, programUuid) =>
    request(`/users/current/projects/${projectUuid}/programs/${programUuid}/join`, {
      method: 'POST',
    }),
  leaveProgram: (projectUuid, programUuid) =>
    request(`/users/current/projects/${projectUuid}/programs/${programUuid}/leave`, {
      method: 'POST',
    }),
  getBDKSetting: (projectUuid) =>
    request(`/projects/${projectUuid}/bdk`, {
      method: 'GET',
    }),
  getUserTicket: (projectUuid, ticketNumber) =>
    request(`/projects/${projectUuid}/tickets/${ticketNumber}`, { method: 'GET' }, true),
  getAvatarSizeSetting: (projectUuid) =>
    request(`/projects/${projectUuid}/avatar-size`, {
      method: 'GET',
    }),
};

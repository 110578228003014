export function BasketAltIcon({ color = '#007AFF' }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 15.6807L9.5 12.1807" stroke={color} strokeLinecap="round" />
      <path d="M8.5 4.68066L6.5 7.68066M15.5 4.68066L17.5 7.68066" stroke={color} strokeLinecap="round" />
      <path d="M13.5 15.6807L14.5 12.1807" stroke={color} strokeLinecap="round" />
      <path
        d="M4.5 9.68066V9.68066C5.08429 9.68066 5.59018 10.0865 5.71693 10.6569L6.80394 15.5484C7.13763 17.05 7.30448 17.8008 7.85289 18.2407C8.4013 18.6807 9.17043 18.6807 10.7087 18.6807H13.2913C14.8296 18.6807 15.5987 18.6807 16.1471 18.2407C16.6955 17.8008 16.8624 17.05 17.1961 15.5484L18.2831 10.6569C18.4098 10.0865 18.9157 9.68066 19.5 9.68066V9.68066"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M19.5 9.68066H4.5C3.94772 9.68066 3.5 9.23295 3.5 8.68066C3.5 8.12838 3.94772 7.68066 4.5 7.68066H19.5C20.0523 7.68066 20.5 8.12838 20.5 8.68066C20.5 9.23295 20.0523 9.68066 19.5 9.68066Z"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}

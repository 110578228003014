import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, Link, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLanguage } from 'src/hooks';
import { checkAndCreateMember } from 'src/store/actions/app';
import { getPartsOfFullName } from 'src/utils/common';
import { addMemberValidationSchema } from 'src/utils/formValidator';
import { createNotification } from 'src/utils/notifications';
import { SelectHookForm } from '../../components/mui/select';
import classes from './accreadition.module.sass';

function AddNewMember({
  projectUuid,
  rolesById,
  userInvitations,
  setShowAddNew,
  fetchUserInvitations,
  refetchMembersOfProject,
}) {
  const { lang, localizeText, getValueByLang } = useLanguage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConflictUser, setIsConflictUser] = useState(false);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      fullName: '',
      roleUuid: '',
    },
    resolver: yupResolver(addMemberValidationSchema(lang)),
  });

  const onSubmit = async ({ email, fullName, roleUuid }) => {
    try {
      const { lastName, firstName, middleName } = getPartsOfFullName(fullName);

      setIsSubmitting(true);
      const invitationUuid = userInvitations[roleUuid]?.[0]?.uuid;
      const success = await checkAndCreateMember(
        {
          projectUuid,
          email,
          invitationUuid,
          roleUuid,
          lastName,
          firstName,
          middleName,
        },
        (errorCode) => {
          if (errorCode === 409) {
            setIsConflictUser(true);
          }
        }
      );
      if (success) {
        createNotification(localizeText.SUCCESS);
        refetchMembersOfProject();
      }
    } finally {
      setIsSubmitting(false);
      fetchUserInvitations(projectUuid);
    }
  };

  const handleReturn = () => {
    reset();
    setShowAddNew(false);
  };

  return (
    <form className={classes.formAddNew} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="E-mail"
        margin="normal"
        InputLabelProps={{ shrink: true }}
        sx={{ width: '100%' }}
        autoFocus
        variant="standard"
        {...register('email')}
        error={errors.email ? true : false}
        helperText={errors.email?.message}
      />

      <TextField
        label={localizeText.FULLNAME}
        margin="normal"
        InputLabelProps={{ shrink: true }}
        sx={{ width: '100%' }}
        variant="standard"
        {...register('fullName')}
        error={errors.fullName ? true : false}
        helperText={errors.fullName?.message}
      />

      <Controller
        name="roleUuid"
        control={control}
        render={({ field }) => (
          <SelectHookForm
            label="Роль"
            selectProps={{ value: field.value, onChange: field.onChange }}
            error={!!errors.roleUuid}
            errorComponent={
              <>{errors.roleUuid && <FormHelperText error={true}>{errors.roleUuid.message}</FormHelperText>}</>
            }
          >
            {Object.keys(userInvitations).map((option, i) => {
              const leftCount = userInvitations[option]?.[0]?.leftCount ?? 0;
              return (
                <MenuItem key={`roleUuid-${i}`} value={option}>
                  {`${getValueByLang(rolesById[option], 'name')} - (${leftCount})`}
                </MenuItem>
              );
            })}
            {Object.keys(userInvitations).length === 0 && (
              <Typography textAlign="center" variant="body1" color="text.secondary">
                {localizeText.NO_MORE_QUOTA}
              </Typography>
            )}
          </SelectHookForm>
        )}
      />

      {isConflictUser && (
        <Typography sx={{ color: (theme) => theme.palette.warning.main }}>
          Указанный пользователь уже зарегистрирован на мероприятии. Для добавления указанного участника, пожалуйста,
          обратитесь в службу поддержки{' '}
          <Typography
            component="span"
            sx={{ textDecoration: 'underline', color: (theme) => theme.palette.primary.main }}
          >
            lk@expoforum.ru
          </Typography>
        </Typography>
      )}

      <Button type="submit" className={classes.submitButton} variant="contained" size="large" disabled={isSubmitting}>
        {localizeText.CONTINUE}
      </Button>
      <Link href="#" underline="hover" textAlign="center" onClick={handleReturn}>
        {localizeText.RETURN_TO_LIST}
      </Link>
    </form>
  );
}

export default AddNewMember;

import { Stack, TextField } from '@mui/material';
import { isEqual } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'src/components/date-picker';

function ProjectsSidebarFilters({ filters = {}, onChangeFilters = () => {} }) {
  const [innerValue, setInnerValue] = useState(filters);
  const isTyping = useRef(null);

  useEffect(() => {
    if (!isEqual(innerValue, filters)) {
      setInnerValue(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (isTyping.current) {
      clearTimeout(isTyping.current);
    }

    isTyping.current = setTimeout(() => {
      onChangeFilters(innerValue);
    }, 500);
  }, [innerValue]);

  return (
    <Stack gap="24px" sx={{ backgroundColor: '#FFFFFF', padding: '40px 20px' }}>
      <TextField
        InputLabelProps={{ shrink: true }}
        label="Тематика"
        value={innerValue?.query}
        onChange={(e) => setInnerValue({ ...(innerValue || {}), query: e.target.value })}
      />
      <DatePicker
        key="plan_since"
        selected={innerValue?.plan_since ? moment.unix(innerValue.plan_since).toDate() : ''}
        onChange={(date) => setInnerValue({ ...(innerValue || {}), plan_since: moment(date).unix() })}
        dateFormat="dd/MM/yyyy"
        customInput={<TextField InputLabelProps={{ shrink: true }} label="Дата начала" fullWidth={true} />}
      />
      <DatePicker
        key="plan_till"
        selected={innerValue?.plan_till ? moment.unix(innerValue.plan_till).toDate() : ''}
        onChange={(date) => setInnerValue({ ...(innerValue || {}), plan_till: moment(date).unix() })}
        dateFormat="dd/MM/yyyy"
        customInput={<TextField InputLabelProps={{ shrink: true }} label="Дата окончания" fullWidth={true} />}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        label="Количество участников"
        type="number"
        value={innerValue?.number_of_participants}
        onChange={(e) => setInnerValue({ ...(innerValue || {}), number_of_participants: e.target.value })}
      />
    </Stack>
  );
}

export default ProjectsSidebarFilters;
